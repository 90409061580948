import React, {useEffect} from "react";
import Loading from "../../components/layout/loading";

const Redirect = () => {

    useEffect(() => {
        window.location.href = "https://minigoal.skemasoft.com/d/r/4b145d48-7598-4934-acea-96628ca6efb5";
    }, []);

    return (
        <Loading />
    )


};

export default Redirect;
