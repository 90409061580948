import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import Loader from "../../components/loader/loader";
import {FailedBoxLink} from "../../components/alert/failed-box";
import {SuccessBoxBtn} from "../../components/alert/success-box";
import {useTranslation} from "react-i18next";
import {CHECK, INDEX, LOGIN, USER_MAIL, VERIFY} from "../endpoints";
import {getWebPathParam} from "../../utils/converter";
import {putElement} from "../../api/base-api";
import {setUserData} from "../../reducers/userReducer";
import {useDispatch} from "react-redux";

const ConfirmRegistration = () => {


  const { t } = useTranslation();
  const dispatch = useDispatch();

  let navigate = useNavigate();
  let {confirmation_code}  = useParams();

  // Check email confirmation
  const [isCheckMailLoading, setCheckMailLoading] = useState(true);


  // Confirm email
  const [isConfirmMailLoading, setConfirmMailLoading] = useState(false);
  const [checkMailException, setCheckMailException] = useState(false);


  useEffect(() => {
      function fetchData() {
          setCheckMailLoading(true);
          putElement(getWebPathParam([VERIFY, USER_MAIL, confirmation_code, CHECK])).then((response) => {
              setCheckMailLoading(false);
          }).catch((e) => {
              setCheckMailException(e.message);
              setCheckMailLoading(false);
          });
      }

      fetchData();
      // eslint-disable-next-line
  }, [confirmation_code]);

    const confirmUserMail = async () => {
        setConfirmMailLoading(true);
        await putElement(getWebPathParam([VERIFY, USER_MAIL, confirmation_code])).then(async (response) => {
            setConfirmMailLoading(false);
            if (response.confirmed) {
                await dispatch(setUserData(response));
                navigate(getWebPathParam([INDEX]));
            }
        }).catch((e) => {
            setCheckMailException(e.message);
            setConfirmMailLoading(false);
        });
    };



    return (
        <main className="bg-white py-16">
            <div className="max-w-lg mx-auto px-4 py-8 w-3/4">
                {
                    isCheckMailLoading ? (
                        <Loader />
                    ) : (
                        checkMailException ? (
                            <FailedBoxLink
                                title={t('app.message.generic_not_found')}
                                message={checkMailException}
                                link={getWebPathParam([LOGIN])}
                                linkTitle={t('app.common.go_to', {label: ' Login ->'})}
                            />
                        ) : (
                            <SuccessBoxBtn
                                title={t('app.confirm_registration.success_title')}
                                message={t('app.confirm_registration.success_message')}
                                label={t('app.confirm_registration.name')}
                                isLoading={isConfirmMailLoading}
                                loadingLabel={t('app.common.loading')}
                                onClick={() => {confirmUserMail(confirmation_code)}}
                            />
                        )
                    )
                }
            </div>
      </main>
  )



};

export default ConfirmRegistration;
