import React, {useEffect} from "react";
import { Navigate, Outlet, useLocation } from "react-router";
import NavigationAuthentication from "./navigation-authentication";
import { useSelector, useDispatch } from "react-redux";
import {loadUserDataIfMissing} from "../../reducers/userReducer";
import {getWebPathParam} from "../../utils/converter";
import * as Endpoint from "../../routes/endpoints";
import Notification from "../partials/Notification/notification";

const RequireAuth = () => {

    const { isAuthenticated } = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const location = useLocation();


    useEffect(() => {
        dispatch(loadUserDataIfMissing());
    }, [dispatch]);

    if (!isAuthenticated) {
        return <Navigate to={getWebPathParam([Endpoint.LOGIN])} state={{ from: location }} />;
    }

    return (
      <div className="min-h-screen">
          <Notification />
          <NavigationAuthentication />
          <Outlet />
      </div>
  );
};

export default RequireAuth;
