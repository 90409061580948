import { useTranslation } from "react-i18next";

const InputPrimarySubmit = ({
  isLoading,
  label,
  LoadingLabel,
  isFullWith,
  disabled = false,
}) => {
  const { t } = useTranslation();

  return isLoading ? (
    <button
      className={`${
        isFullWith ? "w-full" : ""
      } btn bg-red-600 hover:bg-red-700 text-white disabled:border-gray-200 disabled:bg-gray-100 disabled:text-gray-400 disabled:cursor-not-allowed shadow-none`}
      disabled
    >
      <svg
        className="animate-spin w-4 h-4 fill-current shrink-0"
        viewBox="0 0 16 16"
      >
        <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
      </svg>
      <span className="ml-2">
        {LoadingLabel ? LoadingLabel : t("app.common.loading")}
      </span>
    </button>
  ) : (
    <input
      type="submit"
      className={`${
        isFullWith ? "w-full" : ""
      } cursor-pointer disabled:opacity-70 disabled:cursor-not-allowed flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-600`}
      value={label}
      disabled={disabled}
    />
  );
};
export default InputPrimarySubmit;
