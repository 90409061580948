import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import MagazineCard from "../../components/pdf/magazine-card";
import {getElement} from "../../api/base-api";
import {getWebPathParam} from "../../utils/converter";
import {ADS, APP_DASHBOARD, LATEST_MAGAZINES, SEASONS} from "../endpoints";
import LoaderWrapper from "../../components/loader/loader-wrapper";
import {Link} from "react-router-dom";

const Dashboard = () => {
    // eslint-disable-next-line
    const { t } = useTranslation();

    const [isLoading, setLoading] = useState(false);
    // eslint-disable-next-line
    const [exception, setException] = useState(false);
    const [results, setResults] = useState([]);

    // eslint-disable-next-line
    const [isLoadingSeasons, setLoadingSeasons] = useState(false);
    const [refreshData, setRefreshData] = useState(null);
    const [seasons, setSeasons] = useState([]);


    const [ads, setAds] = useState(false);


    function fetchLatestMagazines(signal = null) {

         if(refreshData === null){
            setLoading(true);
        }
        getElement(getWebPathParam([APP_DASHBOARD, LATEST_MAGAZINES]), signal)
            .then(async (response) => {
                setResults(response.magazines);
                setLoading(false);
                setRefreshData(false);
            })
            .catch((e) => {
                setException(e.message);
                setLoading(false);
            });
    }


     function fetchSeasons(signal = null) {
         if(refreshData === null){
            setLoadingSeasons(true);
        }
        getElement(getWebPathParam([SEASONS]), signal)
            .then(async (response) => {
                setSeasons(response);
                setLoadingSeasons(false);
                setRefreshData(false);
            })
            .catch((e) => {
                setException(e.message);
                setLoadingSeasons(false);
            });
    }

    function fetchAds(signal = null) {
        getElement(getWebPathParam([ADS]), signal)
            .then(async (response) => {
                setAds(response);
            })
            .catch((e) => {

            });
    }



    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        fetchLatestMagazines(signal);
        fetchSeasons(signal);
        fetchAds(signal);

        return () => {
            controller.abort();
        };
        // eslint-disable-next-line
    }, []);



    return (
        <div className="py-12">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">


                <div className={(ads && ads.web_picture) ? "pb-5": "border-b border-gray-200 pb-5"}>


                    <LoaderWrapper isLoading={isLoading}>

                        {
                            results.length > 0 && (
                                <>
                                    <h3 className="text-base font-semibold leading-6 text-gray-900">
                                        {t("app.pages.latest_title")}
                                    </h3>
                                    <p className="mt-2 max-w-4xl text-sm text-gray-500">
                                        {t("app.pages.latest_description")}
                                    </p>

                                    <ul
                                        className="mx-auto mt-20 grid max-w-2xl grid-cols-2 gap-y-20 gap-x-6 sm:grid-cols-3 lg:mx-0 md:max-w-none lg:grid-cols-4 sm:gap-y-20 sm:gap-x-8 md:gap-x-10 lg:gap-x-12"
                                    >
                                        {results.map((item) => (
                                            <li key={item.id}>
                                                <MagazineCard
                                                    image={item?.picture?.reference ?? false}
                                                    title={item.title}
                                                    description={item.description}
                                                    magazineDetail={item}
                                                    onRefreshData={(r) => {
                                                        setRefreshData(true);
                                                        fetchLatestMagazines();
                                                        fetchSeasons();
                                                    }}
                                                />
                                            </li>
                                        ))}
                                    </ul>
                                </>
                            )
                        }


                    </LoaderWrapper>
                </div>




                {
                    (ads && ads.web_picture) && (
                        <a href={ads.link} target="_blank" rel="noreferrer">
                            <img src={ads.web_picture.reference} alt='' className={"w-full shadow-lg rounded-2xl"} />
                        </a>
                    )
                }


                <div className="mt-5">
                    <h3 className="text-base font-semibold leading-6 text-gray-900">
                        {t("app.pages.season_title")}
                    </h3>
                    <p className="mt-2 max-w-4xl text-sm text-gray-500">
                        {t("app.pages.season_description")}
                    </p>


                    <LoaderWrapper isLoading={isLoading}>

                        <div className="mx-auto mt-5 grid max-w-2xl auto-rows-fr grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                            {seasons.map((seasons) => (

                                <div
                                    key={seasons.id}
                                    className="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl bg-gray-900 px-8 pb-8 pt-80 sm:pt-48 lg:pt-80"
                                >
                                    <img src={seasons?.picture?.reference ?? '#'} alt="" className="absolute inset-0 -z-10 h-full w-full object-cover" />
                                    <div className="absolute inset-0 -z-10 bg-gradient-to-t from-gray-900 via-gray-900/40" />
                                    <div className="absolute inset-0 -z-10 rounded-2xl ring-1 ring-inset ring-gray-900/10" />

                                    <div className="flex flex-wrap items-center gap-y-1 overflow-hidden text-sm leading-6 text-gray-300">
                                        <div className="-ml-4 flex items-center gap-x-4">
                                            <svg viewBox="0 0 2 2" className="-ml-0.5 h-0.5 w-0.5 flex-none fill-white/50">
                                                <circle cx={1} cy={1} r={1} />
                                            </svg>
                                        </div>
                                    </div>

                                    <h3 className="mt-3 text-lg font-semibold leading-6 text-white z-10">
                                        <Link to={getWebPathParam([SEASONS, seasons.id])}>
                                            <span className="absolute inset-0" />
                                            {seasons.title}
                                        </Link>
                                    </h3>
                                </div>
                            ))}
                        </div>
                    </LoaderWrapper>
                </div>







            </div>
        </div>
    );
};

export default Dashboard;
