import { Dialog, Transition } from '@headlessui/react'
import React, {Fragment, useState} from 'react'
import XIcon from "@heroicons/react/solid/XIcon";
import {Warning} from "../alert/banner";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {postElement} from "../../api/base-api";
import {getWebPathParam} from "../../utils/converter";
import {MAGAZINE, USER} from "../../routes/endpoints";
import PrimaryButton from "../button/primary-btn";
import {refreshDetail} from "../../reducers/userReducer";

const PayPopup = ({isOpen, setIsOpen, magazineDetail, onRefreshData}) => {


    const { detail } = useSelector((state) => state.user);

    const [isLoading, setLoading] = useState(false);
    const [exception, setException] = useState(false);

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const confirm = async () => {
        setLoading(true);
        postElement(getWebPathParam([USER, detail.id, MAGAZINE, magazineDetail.id]))
            .then(async (response) => {
                await dispatch(refreshDetail(response));
                onRefreshData(true);
                setIsOpen(false);
                setLoading(false);
                setException(false);
            })
            .catch((e) => {
                setException(e.message);
                setLoading(false);
            });

    };









    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog
                as="div"
                className="fixed inset-0 z-10 overflow-y-auto"
                onClose={setIsOpen}
            >
                <div className="min-h-screen px-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-900 bg-opacity-30 z-5 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="inline-block h-screen align-middle"
                        aria-hidden="true"
                    >&#8203;</span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div className="inline-block w-full max-w-md my-8 text-left align-middle transition-all transform bg-white shadow-xl">

                            <div className="flex justify-between border-b p-4 items-center">
                                <div>
                                    <h3 className="text-lg font-medium leading-6 text-blue-1000">
                                        {t("app.common.pay_magazine_title")}
                                    </h3>
                                </div>
                                <div>
                                    <button className="bg-white border-0" onClick={() => {setIsOpen(false)}}><XIcon className="w-4 h-4 fill-current flex-shrink-0 text-blue-1000" /></button>
                                </div>
                            </div>

                            <div className="mt-2 p-4">
                                <p className="text-sm text-gray-500" dangerouslySetInnerHTML={{__html: t("app.common."+(magazineDetail.credit > 1 ? "pay_magazine_description_credits" : "pay_magazine_description_credit"),{credit: magazineDetail.credit})}} />

                                {/* Warning */}
                                {exception && <Warning message={exception} />}
                            </div>

                            <div className="p-4 flex before:flex-1 items-center justify-between ">
                                <PrimaryButton
                                    isLoading={isLoading}
                                    label={t("app.common.confirm")}
                                    onClick={async () => {
                                        await confirm();
                                    }}
                                />
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    )
}

export default PayPopup;
