// welcome
export const INDEX = "/";

// oauth
export const OAUTH = "oauth";
export const VERIFY = "verify";
export const LOGIN = "login";
export const REGISTER = "register";
export const RESEND_MAIL = "resend_mail";
export const RESET_PASSWORD = "reset_password";
export const VERIFY_REGISTRATION = "verify-registration";
export const USER_MAIL = "user_mail";
export const USER = "user";
export const PAGES = "pages";
export const PRIVACY = "privacy";
export const TERMS = "terms";
export const TOP_LEVEL = "top-level";
export const TASKS = "tasks";
export const INVITED_USERS = "invited_users";



// App
export const DASHBOARD = "/";
export const MY_MAGAZINE = "my-magazine";
export const ARCHIVE = "archive";
export const HOW_WORK = "how-work";
export const PROFILE = "profile";
export const SETTING = "settings";
export const TRANSACTIONS = "transactions";
export const PAYMENT_TRANSACTIONS = "payment_transactions";


// Dashboard
export const APP_DASHBOARD = "dashboard";
export const LATEST_MAGAZINES = "latest_magazines";
export const MAGAZINE = "magazine";
export const MAGAZINES = "magazines";
export const DOWNLOAD = "download_file";
export const SEASONS = "seasons";
export const ADS = "ads";
export const VIEW = "view";
export const PACKAGES = "packages";
export const PACKAGE = "package";
export const PAYMENT_METHODS = "payment_methods";
export const PAYMENT_METHOD = "payment_method";
export const PAYMENT = "payment";
export const GAME = "mobile-game";
export const PLAY = "play";
export const START = "start";
export const GAME_API = "game";
export const BRIDGE = "bridge";
export const AUTH = "auth";
export const USER_TASK = "user_task";
export const SOCIAL = "social";
export const POINT = "point";
export const SEND = "send";

export const CHECK = "check";
export const NOTIFICATIONS = "notifications";
export const NOTIFICATION_UNREAD = "notification_unread";
export const NOTIFICATION_READ = "notification_read";





export const P404 = "404";


// Params
export const ID = ":id";
