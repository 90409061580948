import level1 from "../resources/game/level-1.png";
import level1Bg from "../resources/game/level-1-bg.png";
import level2 from "../resources/game/level-2.png";
import level2Bg from "../resources/game/level-2-bg.png";
import level3 from "../resources/game/level-3.png";
import level3Bg from "../resources/game/level-3-bg.png";
import level4 from "../resources/game/level-4.png";
import level4Bg from "../resources/game/level-4-bg.png";
import level5 from "../resources/game/level-5.png";
import level5Bg from "../resources/game/level-5-bg.png";
import level6 from "../resources/game/level-6.png";
import level6Bg from "../resources/game/level-6-bg.png";
import level7 from "../resources/game/level-7.png";
import level7Bg from "../resources/game/level-7-bg.png";
import level8 from "../resources/game/level-8.png";
import level8Bg from "../resources/game/level-8-bg.png";
import level9 from "../resources/game/level-9.png";
import level9Bg from "../resources/game/level-9-bg.png";
import level10 from "../resources/game/level-10.png";
import level10Bg from "../resources/game/level-10-bg.png";



export const STATUS_PENDING = 'pending';
export const STATUS_CONFIRMED = 'confirmed';
export const STATUS_DISCONNECTED = 'disconnected';
export const STATUS_ARCHIVED = 'archived';

export const DURATION_1_Y = '1Y';
export const DURATION_1_M = '1M';
export const ATTACHMENT = 'attachment';

export const DRAFT = 'draft';
export const REVIEW = 'review';
export const CONFIRMED = 'confirmed';
export const CANCELED = 'canceled';

export function formatNumber(num) {
    if (Math.abs(num) >= 1.0e+9) {
        return (num / 1.0e+9).toFixed(1).replace(/\.0$/, '') + "B";
    } else if (Math.abs(num) >= 1.0e+6) {
        return (num / 1.0e+6).toFixed(1).replace(/\.0$/, '') + "M";
    } else if (Math.abs(num) >= 1.0e+3) {
        return (num / 1.0e+3).toFixed(1).replace(/\.0$/, '') + "K";
    } else {
        return num.toString();
    }
}

// REPROT TYPE
export const UNDER_EDGES = [
    {
        label: 'Primi calci 1° anno',
        value: 'primi-calci-1'
    },
    {
        label: 'Primi calci 2° anno',
        value: 'primi-calci-2'
    },
    {
        label: 'Pulcini 1° anno',
        value: 'pulcini-1'
    },
    {
        label: 'Pulcini 2° anno',
        value: 'pulcini-2'
    },
    {
        label: 'Esordienti 1° anno',
        value: 'esordienti-1'
    },
    {
        label: 'Esordienti 2° anno',
        value: 'esordienti-2'
    },
    {
        label: 'Under 14',
        value: 'under-14'
    },
    {
        label: 'Under 15',
        value: 'under-15'
    },
    {
        label: 'Under 16',
        value: 'under-16'
    },
    {
        label: 'Under 17',
        value: 'under-17'
    },
    {
        label: 'Juniores',
        value: 'juniores'
    }
];


export const FOOTBALL_POSITIONS = [
    {
        label: 'Portiere',
        value: 'portiere'
    },
    {
        label: 'Difensore Centrale',
        value: 'difensore-centrale'
    },
    {
        label: 'Terzino Destro',
        value: 'terzino-destro'
    },
    {
        label: 'Terzino Sinistro',
        value: 'terzino-sinistro'
    },
    {
        label: 'Difensore Laterale',
        value: 'difensore-laterale'
    },
    {
        label: 'Centrocampista Difensivo',
        value: 'centrocampista-difensivo'
    },
    {
        label: 'Centrocampista Centrale',
        value: 'centrocampista-centrale'
    },
    {
        label: 'Centrocampista Offensivo',
        value: 'centrocampista-offensivo'
    },
    {
        label: 'Regista',
        value: 'regista'
    },
    {
        label: 'Mediano',
        value: 'mediano'
    },
    {
        label: 'Ala Destra',
        value: 'ala-destra'
    },
    {
        label: 'Ala Sinistra',
        value: 'ala-sinistra'
    },
    {
        label: 'Trequartista',
        value: 'trequartista'
    },
    {
        label: 'Attaccante Centrale',
        value: 'attaccante-centrale'
    },
    {
        label: 'Seconda Punta',
        value: 'seconda-punta'
    },
    {
        label: 'Attaccante Esterno',
        value: 'attaccante-esterno'
    },
    {
        label: 'Falso Nove',
        value: 'falso-nove'
    }
];


// REPROT TYPE
export const GAME_LEVELS = [
    {
        level: 1,
        name: 'Non Convocato',
        image: level1,
        bg: level1Bg,
    },
    {
        level: 2,
        name: 'In riserva',
        image: level2,
        bg: level2Bg,
    },
    {
        level: 3,
        name: 'Esordiente',
        image: level3,
        bg: level3Bg,
    },
    {
        level: 4,
        name: 'Titolare',
        image: level4,
        bg: level4Bg,
    },
    {
        level: 5,
        name: 'Capitano',
        image: level5,
        bg: level5Bg,
    },
    {
        level: 6,
        name: 'Fuoriclasse',
        image: level6,
        bg: level6Bg,
    },
    {
        level: 7,
        name: 'Fenomeno',
        image: level7,
        bg: level7Bg,
    },
    {
        level: 8,
        name: 'Leggenda',
        image: level8,
        bg: level8Bg,
    },
    {
        level: 9,
        name: 'Campione del mondo',
        image: level9,
        bg: level9Bg,
    },
    {
        level: 10,
        name: 'Grandmaster',
        image: level10,
        bg: level10Bg,
    }
];


export function getSelectSubParams(data, label, label2) {
    return (data ?? []).map((e) => ({
        id: e.id,
        value: e.id,
        code: e?.code ?? null,
        params: e?.params ?? null,
        label: e[label][label2],
    }) );
}

export function getPeriodType(args) {
    switch (args) {
        case DURATION_1_Y:
            return 'year'
        case DURATION_1_M:
            return 'month'
        default:
            return false
    }
}
