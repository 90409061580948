import React, {useState, useEffect}  from "react";
import { useTranslation } from "react-i18next";
import {useForm} from "react-hook-form";
import InputPrimarySubmit from "../../components/form/input-primay-submit";
import {Warning} from "../../components/alert/banner";
import {useDispatch, useSelector} from "react-redux";
import toast from "react-hot-toast";
import {putElement} from "../../api/base-api";
import {cleanData, getWebPathParam} from "../../utils/converter";
import {USER} from "../endpoints";
import {setUserData} from "../../reducers/userReducer";
import InputCheckbox from "../../components/form/input-checkbox";

const UserSettings = () => {

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { register, handleSubmit, setValue, formState: { errors } } = useForm({ mode: 'onBlur' });
    const { detail } = useSelector((state) => state.user );


    const [isUpdateLoading, setUpdateLoading] = useState(false);
    const [exception, setException] = useState(false);


    useEffect(() => {
        if(detail){
            setValue('id', detail.id);
            setValue('email_notification', detail.email_notification);
            setValue('push_notification', detail.push_notification);

        }
    }, [detail, setValue]);


    const onSubmit = async (data) => {
        cleanData(data);

        setUpdateLoading(true);
        putElement(getWebPathParam([USER, detail.id]), data)
            .then(async (response) => {
                await dispatch(setUserData(response));
                toast.success(t("app.common.update_success"));
                setUpdateLoading(false);
            })
            .catch((e) => {
                setException(e.message);
                setUpdateLoading(false);
            });


    }





    return (
        <div className="py-4">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">


                <div className="divide-y divide-white/5">
                    <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
                        <div>
                            <h2 className="text-base font-semibold leading-7 text-gray-900">
                                {t("app.settings.settings_title")}
                            </h2>
                            <p className="mt-1 text-sm leading-6 text-gray-700">
                                {t("app.settings.settings_desc")}
                            </p>
                        </div>

                        <form className="md:col-span-2 bg-white py-6 px-4  rounded-md shadow-md"  onSubmit={handleSubmit(onSubmit)}>
                            <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">



                                <div className="col-span-full">
                                    <InputCheckbox
                                        label={t("app.settings.email_notification_title")}
                                        description={t("app.settings.email_notification_desc")}
                                        id={'email_notification'}
                                        errors={errors.email_notification}
                                        input={{...register("email_notification", { required: false })}}
                                    />
                                </div>

                                <div className="col-span-full">
                                    <InputCheckbox
                                        label={t("app.settings.push_notification_title")}
                                        description={t("app.settings.push_notification_desc")}
                                        id={'push_notification'}
                                        errors={errors.push_notification}
                                        input={{...register("push_notification", { required: false })}}
                                    />
                                </div>

                            </div>

                            {/* Warning */}
                            {exception && (
                                <Warning
                                    message={exception}
                                />
                            )}

                            <div className="mt-8 flex items-center justify-end">
                                <InputPrimarySubmit
                                    isLoading={isUpdateLoading}
                                    label={t("app.form.save")}
                                />
                            </div>
                        </form>
                    </div>
                </div>


            </div>
        </div>
    );
};

export default UserSettings;
