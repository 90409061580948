import React, {useEffect} from "react";
import { Outlet } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import {loadUserDataIfMissing} from "../../reducers/userReducer";
import Loading from "./loading";

const RequireAuthGame = () => {

    const { isAuthenticated } = useSelector((state) => state.user);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadUserDataIfMissing());
    }, [dispatch]);

    if (!isAuthenticated) {
        return <Loading />;
    }

    return (
        <div className="min-h-screen bg-gray-900 overflow-x-hidden">
          <Outlet />
      </div>
  );
};

export default RequireAuthGame;
