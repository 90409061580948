import {Link} from "react-router-dom";
import React from "react";
import PrimaryButton from "../button/primary-btn";
import {Warning} from "../alert/banner";

export const SuccessBoxLink = ({title, message, link, linkTitle}) => (
    <div className="text-center">
        <svg className="inline-flex w-16 h-16 fill-current mb-6" viewBox="0 0 64 64">
            <circle className="text-green-100" cx="32" cy="32" r="32" />
            <path className="text-green-500" d="m28.5 41-8-8 3-3 5 5 12-12 3 3z" />
        </svg>
        <h1 className="text-3xl text-gray-800 font-bold">{title}</h1>
        <p className="max-w-xl mt-5 mx-auto text-l text-gray-500 mb-8">
            {message}
        </p>
        <Link className="btn bg-red-600 hover:bg-red-700 text-white" to={link}>{linkTitle}</Link>
    </div>
);

export const SuccessBoxBtn = ({title, message, isLoading, label, loadingLabel, onClick, exception}) => (
    <div className="text-center">
        <svg className="inline-flex w-16 h-16 fill-current mb-6" viewBox="0 0 64 64">
            <circle className="text-green-100" cx="32" cy="32" r="32" />
            <path className="text-green-500" d="m28.5 41-8-8 3-3 5 5 12-12 3 3z" />
        </svg>
        <h1 className="text-2xl text-gray-800 font-bold">{title}</h1>
        <p className="max-w-xl mt-5 mx-auto text-l text-gray-500 mb-8">
            {message}
        </p>

        {/* Warning */}
        {exception && (
            <Warning
                message={exception}
            />
        )}
        <PrimaryButton
            label={label}
            isLoading={isLoading}
            LoadingLabel={loadingLabel}
            onClick={onClick}
        />
    </div>
);
