import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./userReducer";
import configReducer from "./configReducer";
import gameReducer from "./gameReducer";

export const store = configureStore({
  reducer: {
    user: userReducer,
    config: configReducer,
    game: gameReducer,
  },
});
