import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// eslint-disable-next-line
import Game from "./game";
// eslint-disable-next-line
import GameOnboarding from "./game-onboarding";
import { postElement } from "../../../api/base-api";
import { getWebPathParam } from "../../../utils/converter";
import { GAME_API, START } from "../../endpoints";
import { onboardGameToken } from "../../../reducers/userReducer";



const StartGame = () => {
    const { game_token } = useSelector((state) => state.user);
    const dispatch = useDispatch();
    // eslint-disable-next-line
    const [isLoading, setLoading] = useState(false);
    // eslint-disable-next-line
    const [isActiveGame, setActiveGame] = useState(false);


    // eslint-disable-next-line
    function featchGameDetails(data) {
        setLoading(true);
        postElement(getWebPathParam([GAME_API, START]), data)
            .then(async (response) => {
                dispatch(onboardGameToken(response.token));
                setLoading(false);
            })
            .catch((e) => {
                setLoading(false);
            });

    }


    useEffect(() => {
        if (game_token){
            setActiveGame(true);
        }
    }, [game_token]); 


    return (
        <>
            {
                isActiveGame ? (
                    <Game />
                ) : (
                    <GameOnboarding
                        onSaveData={async (data) => {
                            await featchGameDetails(data)
                        }}
                        isLoading={isLoading}
                    />
                )
            }
        </>
      
    );
};



export default StartGame;
