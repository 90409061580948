import {Link} from "react-router-dom";
import {Warning} from "../alert/banner";
import PrimaryButton from "../button/primary-btn";
import React from "react";

export const FailedBoxLink = ({title, message, link, linkTitle}) => (
    <div className="text-center">
        <svg className="inline-flex w-16 h-16 fill-current mb-6 place-content-center" viewBox="0 0 64 64" fill="none" stroke="currentColor">
            <circle className="text-red-100" cx="32" cy="32" r="32"  />
            <path className="text-red-500" transform="translate(8, 8)"  strokeLinecap="round" strokeLinejoin="round" strokeWidth="5" d="M12 36L36 12M12 12l24 24"/>
        </svg>
        <h1 className="text-3xl text-gray-800 font-bold">{title}</h1>
        <p className="max-w-xl mt-5 mx-auto text-l text-gray-500 mb-8">
            {message}
        </p>
        <Link className="btn bg-red-600 hover:bg-red-700 text-white" to={link}>{linkTitle}</Link>
    </div>
);

export const FailedBoxBtn = ({title, message, isLoading, label, loadingLabel, onClick, exception}) => (
    <div className="text-center">
        <svg className="inline-flex w-16 h-16 fill-current mb-6 place-content-center" viewBox="0 0 64 64" fill="none" stroke="currentColor">
            <circle className="text-red-100" cx="32" cy="32" r="32"  />
            <path className="text-red-500" transform="translate(8, 8)"  strokeLinecap="round" strokeLinejoin="round" strokeWidth="5" d="M12 36L36 12M12 12l24 24"/>
        </svg>
        <h1 className="text-2xl text-gray-800 font-bold">{title}</h1>
        <p className="max-w-xl mt-5 mx-auto text-l text-gray-500 mb-8">
            {message}
        </p>

        {/* Warning */}
        {exception && (
            <Warning
                color={'red'}
                message={exception}
            />
        )}
        <PrimaryButton
            label={label}
            isLoading={isLoading}
            LoadingLabel={loadingLabel}
            onClick={onClick}
        />
    </div>
);
