import React, { useEffect, useRef, useState } from 'react';
import gsap from "gsap";
import soundSmash from "../../../resources/game/smash.mp3"
import { useDispatch, useSelector } from 'react-redux';
import { handleTapAsync } from '../../../reducers/gameReducer';
import bosterImage from "../../../resources/game/hourglass.png";
import fireBall from "../../../resources/game/fire-ball.png";


const AudioContext = window.AudioContext || window.webkitAudioContext;
const audioContext = new AudioContext();


const TIME_LIMIT = 60000;
const MOLE_SCORE = 100;


// Funzione per caricare e decodificare il suono
async function loadSound(url) {
    const response = await fetch(url);
    const arrayBuffer = await response.arrayBuffer();
    const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
    return audioBuffer;
}

// Funzione per riprodurre il suono
function playSound(audioBuffer) {
    const source = audioContext.createBufferSource(); // Crea una fonte audio
    source.buffer = audioBuffer; // Imposta il buffer con l'audio decodificato
    source.connect(audioContext.destination); // Collega alla destinazione (altoparlanti)
    source.start(); // Riproduce il suono
}

function Moles({ children }) {
    return <div className='h-96 grid grid-rows-3 grid-flow-col gap-4'>{children}</div>;
}


const Timer = ({ time, interval = 1000, onEnd }) => {
    const [internalTime, setInternalTime] = useState(time);
    const timerRef = useRef(time);
    const timeRef = useRef(time);

    useEffect(() => {
        if (internalTime === 0 && onEnd) onEnd();
    }, [internalTime, onEnd]);

    useEffect(() => {
        timerRef.current = setInterval(
            () => setInternalTime((timeRef.current -= interval)),
            interval
        );
        return () => clearTimeout(timerRef.current);
    }, [interval]);

    return (
        <div className="flex items-center justify-center w-full px-1 mt-2">
            <img src={bosterImage} className="w-3.5 h-3.5 mr-1" alt="" />
            <span className="text-sm text-white font-semibold">{internalTime / 1000}s</span>
        </div>
    )
};
const Mole = ({ points, onWhack, image, isVisible, moles, onSetActiveMoleIndex, activeMoleIndex, appearInterval }) => {
    const buttonRef = useRef(null);
    const bobRef = useRef(null);
    const pointsRef = useRef(points);
    const [whacked, setWhacked] = useState(false);

    // Funzione per selezionare un nuovo indice casuale per la talpa
    const setRandomMole = () => {
        let randomIndex;
        do {
            randomIndex = Math.floor(Math.random() * moles.length);
        } while (randomIndex === activeMoleIndex); // Continua a generare finché non trova un indice diverso
        onSetActiveMoleIndex(randomIndex);
    };

    // Funzione chiamata quando la talpa viene colpita
    const whack = () => {
        setWhacked(true);
        onWhack(pointsRef.current);
    };

    useEffect(() => {
        if (isVisible) {
            // Imposta la talpa visibile e gestisci l'animazione
            gsap.set(buttonRef.current, {
                yPercent: 100,
                display: "block"
            });
            bobRef.current = gsap.to(buttonRef.current, {
                yPercent: 0,
                duration: appearInterval,
                yoyo: true, // Completa il ciclo di salita e discesa
                repeat: 1,  // Solo una ripetizione per ciclo completo
                onComplete: () => {
                    // Quando l'animazione è completa, seleziona una nuova talpa
                    setRandomMole();
                },
            });
        } else {
            // Nascondi la talpa se non è visibile
            bobRef.current?.kill();
            gsap.set(buttonRef.current, { display: "none" });
        }

        return () => {
            bobRef.current?.kill();
        };
        // eslint-disable-next-line
    }, [isVisible, moles]);

    useEffect(() => {
        if (whacked) {
            // Se la talpa è colpita, nascondila velocemente e passa alla successiva
            pointsRef.current = points;
            bobRef.current.pause();  // Pausa l'animazione se viene colpita
            gsap.to(buttonRef.current, {
                yPercent: 100,
                duration: 0.1,
                onComplete: () => {


                    loadSound(soundSmash).then(audioBuffer => {
                        playSound(audioBuffer);
                    });
                    setRandomMole(); // Seleziona una nuova talpa
                    setWhacked(false);
                }
            });
        }
        // eslint-disable-next-line
    }, [whacked]);

    return (
        <div className="mole-hole">
            <div
                className="mole"
                style={{
                    backgroundImage: `url(${image})`,
                }}
                ref={buttonRef}
                onClick={whack}
            >
                <span className="sr-only">Whack</span>
            </div>
        </div>
    );
};


const Game = ({ image }) => {

    const dispatch = useDispatch();
    const { startTime } = useSelector((state) => state.game);
    


    const [playing, setPlaying] = useState(false);
    const [finished, setFinished] = useState(false);
    const [score, setScore] = useState(0);
    const [activeMoleIndex, setActiveMoleIndex] = useState(null); // Indice del buco attivo
    const [appearInterval, setAppearInterval] = useState(startTime);
    const [isActiveBomb, setActiveBomb] = useState(false);
    const [nextImage, setNextImage] = useState(image);
    const [bombCounter, setBombCounter] = useState(10);


    const generateMoles = () =>
        new Array(9).fill(0).map(() => ({
            speed: gsap.utils.random(0.5, 1),
            points: MOLE_SCORE
        }));

    const [moles, setMoles] = useState(generateMoles());
    


    const endGame = () => {
        setPlaying(false);
        setFinished(true);
        setAppearInterval(startTime);
    };

    const startGame = () => {
        setScore(0);
        setMoles(generateMoles());
        setPlaying(true);
        setFinished(false);
        setActiveMoleIndex(Math.floor(Math.random() * moles.length)); // Inizializza il ciclo delle talpe
    };

    const onWhack = (points) => setScore(score + points);

    return (
        <>
            {!playing && !finished && (
                <>
                    <div class="flex flex-col relative">
                        <div class=" absolute inset-0 flex justify-center items-center bg-black bg-opacity-50 rounded-full transition-opacity duration-300 text-white ">
                            <div onClick={startGame} className='cursor-pointer btn rounded-full  px-4 text-xl font-semibold  text-white bg-red-700'>Inizia il gioco</div>
                        </div>
                        <img src={image} class="mx-auto w-full flex-shrink-0 rounded-full" alt="" />
                    </div>
                </>
            )}
            {playing && (
                <>
                    <Timer time={TIME_LIMIT} onEnd={endGame} />
                    <Moles>
                        {moles.map(({ speed, points }, id) => (
                            <Mole
                                image={nextImage}
                                key={id}
                                appearInterval={appearInterval}
                                onWhack={(r) => {
                                    onWhack(r);
                                    dispatch(handleTapAsync(isActiveBomb ? {game_type: 'bomb', decreasepoint: true } : { game_type: 'bomb' }));
                                    setAppearInterval(prevStato => {
                                        return prevStato - 0.01;//gsap.utils.random(0.2, 0.7);
                                    });
                                    setBombCounter(prevStato => {
                                        return prevStato - 1;//gsap.utils.random(0.2, 0.7);
                                    });
                                    setActiveBomb(false);
                                }}
                                points={points}
                                speed={speed}
                                moles={moles}
                                activeMoleIndex={activeMoleIndex}
                                onSetActiveMoleIndex={(r) => {
                                    setActiveMoleIndex(r);
                                    let rand = Math.round(Math.random());
                                    if (rand && bombCounter <= 0){
                                        setActiveBomb(true);
                                        setNextImage(fireBall);
                                        setBombCounter(gsap.utils.random(3, 10));
                                    }else{
                                        setActiveBomb(false);
                                        setNextImage(image);
                                    }
                                }}
                                isVisible={id === activeMoleIndex}
                            />
                        ))}
                    </Moles>
                </>
            )}
            {finished && (
                <>
                    <div class="flex flex-col relative">
                        <div class=" absolute inset-0 flex justify-center items-center bg-black bg-opacity-50 rounded-full transition-opacity duration-300 text-white ">
                            <div onClick={startGame} className='cursor-pointer btn  rounded-full px-4 text-xl font-bold  text-white bg-red-700'>Gioca Ancora!</div>
                        </div>
                        <img src={image} class="mx-auto w-full flex-shrink-0 rounded-full" alt="" />
                    </div>
                </>
            )}
        </>
    );
};

const GameWhackBall = ({ image }) => {
    return (
        <div className="">
            <Game image={image} />
        </div>
    );
};

export default GameWhackBall;
