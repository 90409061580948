import {
  GET_COUNTRIES,
  GET_PHONE_PREFIXES,
  GET_CURRENCIES,
  GET_PORTS,
  GET_DELIVERY_TYPES,
  GET_TERM_TYPES,
  GET_SHIPPING_LINES,
  GET_DOCUMENT_TYPES,
  GET_EVENT_TYPES,
  GET_CONTAINER_TYPES,
  NOTIFICATION_UNREAD,
  GET_PERMISSIONS,
  GET_PACKAGING_TYPES,
  GET_NOTIFICATION_TYPES,
  COMPANY,
  WAREHOUSES,
  GET_EMAIL_PROVIDERS,
  GET_VESSELS,
  GET_SHIPPING_TYPES, GET_LANGUAGES, GET_CITIES, GET_UNITS, GET_WEBHOOK_EVENTS,
} from "./endpoints";
import API, { getApi, setApi } from "../utils/apiUtils";
import {getPathParam, serialize} from "../utils/converter";

// CONFIG
export const getCountries = async () => {
  let data = await getApi(GET_COUNTRIES);
  if (!data) {
    data = await API.get(GET_COUNTRIES);
    await setApi(GET_COUNTRIES, data);
  }
  return data;
};


export const getUnits = async () => {
  let data = false;//await getApi(GET_UNITS);
  if (!data) {
    data = await API.get(GET_UNITS);
    await setApi(GET_UNITS, data);
  }
  return data;
};

export const getWebhookEvents = async () => {
  let data = false;//await getApi(GET_UNITS);
  if (!data) {
    data = await API.get(GET_WEBHOOK_EVENTS);
    await setApi(GET_WEBHOOK_EVENTS, data);
  }
  return data;
};


export const getCities = ({params = false, signal = false}) =>
    API.get(getPathParam([GET_CITIES] ) + (params ? serialize(params)  : ''), signal ? {signal: signal} : {});

export const getLanguages = async () => {
  let data = await getApi(GET_LANGUAGES);
  if (!data) {
    data = await API.get(GET_LANGUAGES);
    await setApi(GET_LANGUAGES, data);
  }
  return data;
};


export const getPhonePrefixes = async () => {
  let data = await getApi(GET_PHONE_PREFIXES);
  if (!data) {
    data = await API.get(GET_PHONE_PREFIXES);
    await setApi(GET_PHONE_PREFIXES, data);
  }
  return data;
};

export const getCurrencies = async () => {
  let data = await getApi(GET_CURRENCIES);
  if (!data) {
    data = await API.get(GET_CURRENCIES);
    await setApi(GET_CURRENCIES, data);
  }
  return data;
};

export const getPorts = ({params = false, signal = false}) =>
    API.get(getPathParam([GET_PORTS] ) + (params ? serialize(params)  : ''), signal ? {signal: signal} : {});

export const getPortData = ({params, signal = false}) =>
    API.get(getPathParam([GET_PORTS] ) + (params ? serialize(params)  : ''), signal ? {signal: signal} : {});

export const getVessels = ({params = false, signal = false}) =>
    API.get(getPathParam([GET_VESSELS] ) + (params ? serialize(params)  : ''), signal ? {signal: signal} : {});


export const getDeliveryTypes = async () => {
  let data = await getApi(GET_DELIVERY_TYPES);
  if (!data || (data.length === 0)) {
    data = await API.get(GET_DELIVERY_TYPES);
    await setApi(GET_DELIVERY_TYPES, data);
  }
  return data;
};


export const getShippingTypes = async () => {
  let data = await getApi(GET_SHIPPING_TYPES);
  if (!data || (data.length === 0)) {
    data = await API.get(GET_SHIPPING_TYPES);
    await setApi(GET_SHIPPING_TYPES, data);
  }
  return data;
};

export const getTermTypes = async () => {
  let data = await getApi(GET_TERM_TYPES);
  if (!data || ((data?.length ?? 0) === 0)) {
    data = await API.get(GET_TERM_TYPES);
    await setApi(GET_TERM_TYPES, data);
  }
  return data;
};

export const getShippingLines = async () => {
  let data = await getApi(GET_SHIPPING_LINES);
  if (!data || (data.length === 0)) {
    data = await API.get(GET_SHIPPING_LINES);
    await setApi(GET_SHIPPING_LINES, data);
  }
  return data;
};

export const getDocumentTypes = async () => {
  let data = await getApi(GET_DOCUMENT_TYPES);
  if (!data || (data.length === 0)) {
    data = await API.get(GET_DOCUMENT_TYPES);
    await setApi(GET_DOCUMENT_TYPES, data);
  }
  return data;
};

export const getEventTypes = async () => {
  let data = await getApi(GET_EVENT_TYPES);
  if (!data || (data.length === 0)) {
    data = await API.get(GET_EVENT_TYPES);
    await setApi(GET_EVENT_TYPES, data);
  }
  return data;
};

export const getContainerTypes = async () => {
  let data = await getApi(GET_CONTAINER_TYPES);
  if (!data || (data.length === 0)) {
    data = await API.get(GET_CONTAINER_TYPES);
    await setApi(GET_CONTAINER_TYPES, data);
  }
  return data;
};


export const getPackagingTypes = async () => {
  let data = await getApi(GET_PACKAGING_TYPES);
  if (!data || (data.length === 0)) {
    data = await API.get(GET_PACKAGING_TYPES);
    if(data.length > 0){
      await setApi(GET_PACKAGING_TYPES, data);
    }
  }
  return data;
};

export const getNotificationTypes = async () => {
  let data = await getApi(GET_NOTIFICATION_TYPES);
  if (!data || (data.length === 0)) {
    data = await API.get(GET_NOTIFICATION_TYPES);
    await setApi(GET_NOTIFICATION_TYPES, data);
  }
  return data;
};


export const getWarehouses = ({company_id, params, signal}) =>
    API.get(getPathParam([COMPANY, company_id, WAREHOUSES] ) + (params ? serialize(params)  : ''), {signal: signal});

export const getEmailProvider = ({signal}) =>
    API.get(getPathParam([GET_EMAIL_PROVIDERS] ), {signal: signal});

export const getPermissionTypes = async () => API.get(GET_PERMISSIONS);

export const getNotificationCounter = async () => API.get(NOTIFICATION_UNREAD);

export const getSignedRequests = ({ params }) =>
  API.post('signed_requests' + (params ? serialize(params) : ""));
