import { Popover } from '@headlessui/react'
import {AnimatePresence, motion} from 'framer-motion/dist/framer-motion'

import { Button } from '../landing/button'
import Logo from '../../resources/images/logo_minigoal.png';
import { NavLinks } from '../landing/nav-links'
import {ChevronUpIcon, MenuIcon} from "@heroicons/react/outline";



function MobileNavLink({ ...props }) {
    return (
        <a
            href={props.href}
            className="block text-base leading-7 tracking-tight text-gray-700"
            {...props}
        >
            {props.title}
        </a>
    )
}

export function Navigation() {
    return (
        <header className="border-b border-gray-100 bg-white">
            <nav>
                <div className="mx-auto max-w-7xl py-4 px-4 sm:px-6 lg:px-8 relative z-50 flex justify-between ">
                    <div className="relative z-10 flex items-center gap-16">
                        <a href="https://www.minigoal.it/" aria-label="Home">
                            <img src={Logo}  className="h-10 w-auto" alt="" />
                        </a>
                        <div className="hidden lg:flex lg:gap-10">
                            <NavLinks />
                        </div>
                    </div>
                    <div className="flex items-center gap-6">
                        <Popover className="lg:hidden">
                            {({ open }) => (
                                <>
                                    <Popover.Button
                                        className="relative z-10 -m-2 inline-flex text-gray-700 items-center rounded-lg stroke-gray-900 p-2 hover:bg-gray-200/50 hover:stroke-gray-600 active:stroke-gray-900 ui-not-focus-visible:outline-none"
                                        aria-label="Toggle site navigation"
                                    >
                                        {({ open }) =>
                                            open ? (
                                                <ChevronUpIcon className="h-6 w-6 " />
                                            ) : (
                                                <MenuIcon className="h-6 w-6 " />
                                            )
                                        }
                                    </Popover.Button>
                                    <AnimatePresence initial={false}>
                                        {open && (
                                            <>
                                                <Popover.Overlay
                                                    static
                                                    as={motion.div}
                                                    initial={{ opacity: 0 }}
                                                    animate={{ opacity: 1 }}
                                                    exit={{ opacity: 0 }}
                                                    className="fixed inset-0 z-0 bg-gray-300/60 backdrop-blur"
                                                />
                                                <Popover.Panel
                                                    static
                                                    as={motion.div}
                                                    initial={{ opacity: 0, y: -32 }}
                                                    animate={{ opacity: 1, y: 0 }}
                                                    exit={{
                                                        opacity: 0,
                                                        y: -32,
                                                        transition: { duration: 0.2 },
                                                    }}
                                                    className="absolute inset-x-0 top-0 z-0 origin-top rounded-b-2xl bg-gray-50 px-6 pb-6 pt-32 shadow-2xl shadow-gray-900/20"
                                                >
                                                    <div className="space-y-4">
                                                        <MobileNavLink href="https://www.minigoal.it/#last-minigoal" title="Nuove Uscite" />
                                                        <MobileNavLink href="https://www.minigoal.it/#about-us" title="Chi siamo" />
                                                        <MobileNavLink href="https://www.minigoal.it/#contact-us" title="Contattaci" />
                                                        <MobileNavLink href="https://www.minigoal.it/#faqs" title="FAQs" />
                                                    </div>
                                                    <div className="mt-8 flex flex-col gap-4">
                                                        <Button href="/login" variant="outline">
                                                            Accedi
                                                        </Button>
                                                        <Button href="/register">Registrati</Button>
                                                    </div>
                                                </Popover.Panel>
                                            </>
                                        )}f
                                    </AnimatePresence>
                                </>
                            )}
                        </Popover>
                        <Button href="/login" variant="outline" className="hidden lg:block">
                            Accedi
                        </Button>
                        <Button href="/register" className="hidden lg:block">
                            Registrati
                        </Button>
                    </div>
                </div>
            </nav>
        </header>
    )
}
