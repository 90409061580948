import React from "react";
import { useTranslation } from "react-i18next";
import {Link} from "react-router-dom";
import {getWebPathParam} from "../utils/converter";
import {DASHBOARD} from "./endpoints";

const Page404 = () => {
  const { t } = useTranslation();
  return (
      <div className="h-full flex flex-col before:flex-1 after:flex-1  ">

      <div className="max-w-7xl mx-auto lg:px-24 lg:py-24 md:py-20 md:px-44 px-4 py-24 items-center flex justify-center flex-col-reverse lg:flex-row md:gap-28 gap-16">
          <div className="text-center">
              <h1 className="my-2 text-gray-900 font-bold text-9xl">404</h1>
              <h1 className="my-2 text-gray-800 font-bold text-2xl">{t("app.common.not_found_title")}</h1>
              <p className="my-2 text-gray-800">{t("app.common.not_found_description")}</p>
              <Link className="btn mt-2 bg-red-600 hover:bg-red-700 text-white" to={getWebPathParam([DASHBOARD])}>{t('app.common.go_to', {label: ' Dashboard ->'})}</Link>
          </div>

      </div>
      </div>

  );
};

export default Page404;
