import React, {Fragment, useState} from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { MenuIcon, XIcon} from '@heroicons/react/outline'
import logo from "../../resources/images/logo_minigoal.png";
import {clearToken} from "../../utils/tokenUtil";
import {oauthLogout} from "../../api/user";
import {useTranslation} from "react-i18next";
import {NavLink, useLocation} from "react-router-dom";
import {getWebPathParam} from "../../utils/converter";
import * as Endpoint from "../../routes/endpoints";
import {useSelector} from "react-redux";
import InputSearch from "../common/form/input-search";
import noImage from "../../resources/images/user-placeholder.jpg"
import Notification from "./notification";
import BuyPopup from "../popup/buy-popup";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function NavigationAuthentication() {

    const { t } = useTranslation();
    const { detail } = useSelector((state) => state.user);
    const { pathname } = useLocation();


    const [isOpenBuyCredit, setOpenBuyCredit] = useState(false);


    const menu = [
        {
            id: 1,
            link: getWebPathParam([Endpoint.DASHBOARD]),
            name: t("app.menu.dashboard")
        },
        {
            id: 2,
            link: getWebPathParam([Endpoint.MY_MAGAZINE]),
            name: t("app.menu.my-minigoal")
        },
        {
            id: 3,
            link: getWebPathParam([Endpoint.ARCHIVE]),
            name: t("app.menu.archive")
        },
        {
            id: 4,
            link: getWebPathParam([Endpoint.HOW_WORK]),
            name: t("app.menu.how-work")
        }
    ];
    const userMenu = [
        {
            id: 1,
            link: getWebPathParam([Endpoint.PROFILE]),
            name: t("app.menu.profile"),
            type: 'link'
        },
        {
            id: 2,
            link: getWebPathParam([Endpoint.SETTING]),
            name: t("app.menu.settings"),
            type: 'link'
        },
        {
            id: 3,
            link: getWebPathParam([Endpoint.TRANSACTIONS]),
            name: t("app.menu.transactions"),
            type: 'link'
        },
        {
            id: 4,
            onClick: () => {
                setOpenBuyCredit(true);
            },
            name: t("app.menu.buy-credit"),
            type: 'div'
        },
        {
            id: 5,
            onClick: async () => {
                await oauthLogout().then(res => {
                    clearToken();
                    localStorage.clear();
                    window.location.reload();
                });
            },
            name: t("app.menu.logout"),
            type: 'div'
        }
    ];


    return (
        <>
            <Disclosure as="nav" className="bg-red-600 shadow">
                {({ open, close }) => (
                    <>
                        <div className="mx-auto max-w-7xl px-2 sm:px-4 lg:px-8">
                            <div className="flex h-16 justify-between">
                                <div className="flex px-2 lg:px-0">
                                    <div className="flex flex-shrink-0 items-center">
                                        <a href="https://www.minigoal.it/">

                                            <img
                                                className="block h-8 w-auto lg:hidden"
                                                src={logo}
                                                alt="Minigoal"
                                            />
                                            <img
                                                className="hidden h-8 w-auto lg:block"
                                                src={logo}
                                                alt="Minigoal"
                                            />
                                        </a>
                                    </div>
                                    <div className="hidden lg:ml-6 lg:flex lg:space-x-8">

                                        {
                                            menu.map(data => (
                                                <NavLink
                                                    key={data.id}
                                                    to={data.link}
                                                    className={
                                                        classNames(
                                                            pathname === data.link && "border-white",
                                                            "inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-white hover:border-white"
                                                        )
                                                    }
                                                >
                                                    {data.name}
                                                </NavLink>
                                            ))
                                        }
                                    </div>
                                </div>
                                <div className="flex flex-1 items-center justify-center px-2 lg:ml-6 lg:justify-end">

                                    <p className="text-xs font-bold text-gray-900 mr-3 bg-white p-2 rounded-md shadow-md uppercase hidden lg:block">

                                        {
                                            (detail && detail.credit) ?
                                                ( detail.credit === 0 ) ? <>0 crediti disponibili</> : (
                                                    detail.credit > 1 ? t("app.common.credits_available", {credit: detail.credit}) : t("app.common.credit_available", {credit: detail.credit})
                                                ) : (
                                                    <>0 crediti disponibili</>
                                                )
                                        }
                                    </p>


                                    <div className="w-full max-w-lg lg:w-64">
                                        <InputSearch
                                            isDynamicSearch={true}
                                            onSearch={(r) => {

                                            }}
                                            hasMargin={false}
                                        />
                                    </div>
                                </div>
                                <div className="flex items-center lg:hidden">
                                    {/* Mobile menu button */}
                                    <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-white hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                        <span className="sr-only">Open main menu</span>
                                        {open ? (
                                            <XIcon className="block h-6 w-6" aria-hidden="true" />
                                        ) : (
                                            <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                                        )}
                                    </Disclosure.Button>
                                </div>
                                <div className="hidden lg:ml-4 lg:flex lg:items-center">

                                    <Notification />

                                    {/* Profile dropdown */}
                                    <Menu as="div" className="relative ml-4 flex-shrink-0">
                                        <div>
                                            <Menu.Button className="flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                                <span className="sr-only">Open user menu</span>
                                                <img
                                                    className="h-8 w-8 rounded-full"
                                                    src={detail?.picture?.reference ?? noImage}
                                                    alt=""
                                                />
                                            </Menu.Button>
                                        </div>
                                        <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-100"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95"
                                        >
                                            <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                {
                                                    userMenu.map(data => (
                                                        data.type === 'link' ? (
                                                            <Menu.Item>
                                                                {({ active }) => (
                                                                    <NavLink
                                                                        key={data.id}
                                                                        to={data.link}
                                                                        className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                                                    >
                                                                        {data.name}
                                                                    </NavLink>
                                                                )}
                                                            </Menu.Item>
                                                        ): (
                                                            <Menu.Item>
                                                                <div
                                                                    key={data.id}
                                                                    onClick={data.onClick}
                                                                    className={'block px-4 py-2 text-sm text-gray-700 cursor-pointer'}
                                                                >
                                                                    {data.name}
                                                                </div>
                                                            </Menu.Item>
                                                        )
                                                    ))
                                                }
                                            </Menu.Items>
                                        </Transition>
                                    </Menu>
                                </div>
                            </div>
                        </div>

                        <Disclosure.Panel className="lg:hidden">
                            <div className="space-y-1 pb-3 pt-2">

                                {
                                    menu.map(data => (
                                        <NavLink
                                            key={data.id}
                                            to={data.link}
                                            onClick={() => {
                                                close()
                                            }}
                                            className="block cursor-pointer py-2 pl-3 pr-4 text-base font-medium text-white"
                                        >
                                            {data.name}
                                        </NavLink>
                                    ))
                                }

                            </div>
                            <div className="border-t border-gray-200 pb-3 pt-4">
                                <div className="flex items-center px-4">
                                    <div className="flex-shrink-0">
                                        <img
                                            className="h-10 w-10 rounded-full"
                                            src={detail?.picture?.reference ?? noImage}
                                            alt=""
                                        />
                                    </div>
                                    <div className="ml-3">
                                        <div className="text-base font-medium text-white">{detail?.firstname ?? ""} {detail?.lastname ?? ""}</div>
                                        <div className="text-sm font-medium text-white">{detail?.email ?? ""}</div>
                                    </div>
                                    <Notification />


                                    <p className="text-xs font-bold text-gray-900 ml-3 bg-white p-2  rounded-md shadow-md uppercase lg:hidden">

                                        {
                                            (detail && detail.credit) ?
                                                ( detail.credit === 0 ) ? <>0 crediti disponibili</> : (
                                                    detail.credit > 1 ? t("app.common.credits_available", {credit: detail.credit}) : t("app.common.credit_available", {credit: detail.credit})
                                                ) : (
                                                    <>0 crediti disponibili</>
                                                )
                                        }
                                    </p>
                                </div>



                                <div className="mt-3 space-y-1">

                                    {
                                        userMenu.map(data => (
                                            data.type === 'link' ? (
                                                <NavLink
                                                    key={data.id}
                                                    to={data.link}
                                                    onClick={() => {
                                                        close()
                                                    }}
                                                    className="block cursor-pointer py-2 pl-3 pr-4 text-base font-medium text-white"
                                                >
                                                    {data.name}
                                                </NavLink>
                                            ) : (
                                                <div
                                                    key={data.id}
                                                    onClick={data.onClick}
                                                    className="block cursor-pointer py-2 pl-3 pr-4 text-base font-medium text-white"
                                                >
                                                    {data.name}
                                                </div>
                                            )
                                        ))
                                    }
                                </div>
                            </div>
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>

            {
                isOpenBuyCredit && (
                    <BuyPopup
                        isOpen={isOpenBuyCredit}
                        setIsOpen={(r) => {
                            setOpenBuyCredit(r);
                        }}
                    />
                )
            }
        </>
    )
}




