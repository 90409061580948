import React, {useState, useEffect}  from "react";
import { useTranslation } from "react-i18next";
import {useForm} from "react-hook-form";
import InputText from "../../components/form/input-text";
import {emailRegex, passwordRegex} from "../../constants/regex";
import InputPrimarySubmit from "../../components/form/input-primay-submit";
import {Warning} from "../../components/alert/banner";
import noImage from "../../resources/images/user-placeholder.jpg"
import {useDispatch, useSelector} from "react-redux";
import SimpleUpload from "../../components/upload/simple-upload";
import {toast} from "react-hot-toast";
import {putElement} from "../../api/base-api";
import {cleanData, getWebPathParam} from "../../utils/converter";
import {USER} from "../endpoints";
import {setUserData} from "../../reducers/userReducer";

const UserProfile = () => {

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { register, handleSubmit, setValue, formState: { errors } } = useForm({ mode: 'onBlur' });
    const { detail } = useSelector((state) => state.user );


    //Picture
    const [picture, setPicture] = useState(false);
    const [removePicture, setRemovePicture] = useState(false);
    const [newPicture, setNewPicture] = useState(false);

    const [isUpdateLoading, setUpdateLoading] = useState(false);
    const [exception, setException] = useState(false);


    useEffect(() => {
        if(detail){
            setValue('id', detail.id);
            setValue('firstname', detail.firstname);
            setValue('lastname', detail.lastname);

            if(detail.picture){
                setPicture(detail.picture.reference)
            }
        }
    }, [detail, setValue]);


    const onSubmit = async (data) => {
        cleanData(data);
        if(data['password'] === ''){
            delete data['password']
        }

        if(newPicture !== false){
            data['picture'] = {media_url: newPicture}
        }

        if(removePicture && newPicture === false){
            data['remove_picture'] = true;
        }

        setUpdateLoading(true);
        putElement(getWebPathParam([USER, detail.id]), data)
            .then(async (response) => {
                await dispatch(setUserData(response));
                toast.success(t("app.common.update_success"));
                setNewPicture(false);
                setRemovePicture(false);
                setUpdateLoading(false);
                setException(false);
            })
            .catch((e) => {
                setException(e.message);
                setUpdateLoading(false);
            });


    }





    return (
        <div className="py-4">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">


                    <div className="divide-y divide-white/5">
                        <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
                            <div>
                                <h2 className="text-base font-semibold leading-7 text-gray-900">
                                    {t("app.settings.profile_title")}
                                </h2>
                                <p className="mt-1 text-sm leading-6 text-gray-700">
                                    {t("app.settings.profile_desc")}
                                </p>
                            </div>

                            <form className="md:col-span-2 bg-white py-6 px-4  rounded-md shadow-md"  onSubmit={handleSubmit(onSubmit)}>
                                <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">


                                    <div className="col-span-full flex items-center gap-x-8">
                                        <img
                                            src={picture ? picture : noImage}
                                            alt=""
                                            className="h-24 w-24 flex-none rounded-lg bg-gray-800 object-cover"
                                        />
                                        <div>
                                            <SimpleUpload
                                                label={t("app.common.edit_image")}
                                                onUpload={r => {
                                                    setPicture(r.reference);
                                                    setNewPicture(r.reference);
                                                }} />
                                        </div>

                                        <div className="ml-1 flex">

                                            { /*
                                                picture && (
                                                    <button type="button"
                                                            onClick={() => {
                                                                setRemovePicture(true);
                                                                setPicture(false);
                                                            }}
                                                            className="bg-transparent py-2 px-3 border border-transparent rounded-md text-sm font-medium text-red-900"
                                                    >{t("app.common.remove")}
                                                    </button>
                                                )
                                            */ }
                                        </div>




                                    </div>



                                    <div className="sm:col-span-3">
                                        <InputText
                                            id={'firstname'}
                                            type={'text'}
                                            errors={errors.firstname}
                                            input={{...register("firstname", { required: true })}}
                                            label={t("app.form.firstname")}
                                        />
                                    </div>


                                    <div className="sm:col-span-3">
                                        <InputText
                                            id={'lastname'}
                                            type={'text'}
                                            errors={errors.lastname}
                                            input={{...register("lastname", { required: true })}}
                                            label={t("app.form.lastname")}
                                        />
                                    </div>

                                    <div className="col-span-full">
                                        <InputText
                                            id={'email'}
                                            type={'email'}
                                            errors={errors.email}
                                            input={{...register("email", { required: false, pattern: emailRegex })}}
                                            label={t("app.form.email")}
                                            isDisabled={true}
                                            defaultValue={detail?.email ?? ''}

                                        />
                                    </div>

                                    <div className="col-span-full">
                                        <InputText
                                            id={'password'}
                                            type={'Password'}
                                            errors={errors.password}
                                            input={{...register("password", { required: false, pattern: passwordRegex })}}
                                            label={t("app.form.password")}
                                            warningRegex={t("app.warning.password_regex")}
                                        />

                                    </div>



                                </div>

                                {/* Warning */}
                                {exception && (
                                    <Warning
                                        message={exception}
                                    />
                                )}

                                <div className="mt-8 flex items-center justify-end">
                                    <InputPrimarySubmit
                                        isLoading={isUpdateLoading}
                                        label={t("app.form.save")}
                                    />
                                </div>
                            </form>
                        </div>
                        </div>


            </div>
        </div>
    );
};

export default UserProfile;
