import { Dialog, Transition } from '@headlessui/react'
import React, {Fragment, useEffect, useState} from 'react'
import XIcon from "@heroicons/react/solid/XIcon";
import {Warning} from "../alert/banner";
import {useTranslation} from "react-i18next";
import {getElement, postElement} from "../../api/base-api";
import {getWebPathParam} from "../../utils/converter";
import {PACKAGE, PACKAGES, PAYMENT_METHOD, PAYMENT_METHODS} from "../../routes/endpoints";
import PrimaryButton from "../button/primary-btn";
import { RadioGroup } from '@headlessui/react'
import LoaderWrapper from "../loader/loader-wrapper";
import NumberFormat from "react-number-format";
import {ArrowLeftIcon} from "@heroicons/react/outline";


const STEP_PACKAGE = 'package';
const STEP_PAYMENT_METHOD = 'payment_method';
const STEP_OFFLINE_PAYMENT = 'offline_payment';


const BuyPopup = ({isOpen, setIsOpen}) => {

    const { t } = useTranslation();

    const { protocol, host } = window.location;
    const url = `${protocol}//${host}/payment/check`;

    // Packages
    const [selectedPackage, setSelectedPackage] = useState(null)
    const [isLoadingPackages, setLoadingPackages] = useState(false);
    const [packages, setPackages] = useState([]);


    // Packages
    const [selectedMethod, setSelectedMethod] = useState(null)
    const [isLoadingMethods, setLoadingMethods] = useState(false);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [paymentDetail, setPaymentDetail] = useState(false);


    // generic
    const [paymentStep, setPaymentStep] = useState(STEP_PACKAGE);
    const [exception, setException] = useState(false);
    const [isLoading, setLoading] = useState(false);


    useEffect(() => {

        const controller = new AbortController();
        const signal = controller.signal;

        function fetchPackages() {
            if(isOpen){
                setLoadingPackages(true);
                getElement(getWebPathParam([PACKAGES]), signal)
                    .then(async (response) => {
                        setPackages(response);
                        setLoadingPackages(false);
                    })
                    .catch((e) => {
                        setException(e.message);
                        setLoadingPackages(false);
                    });
            }

        }


        function fetchPaymentMethods() {
            if(isOpen){
                setLoadingMethods(true);
                getElement(getWebPathParam([PAYMENT_METHODS]), signal)
                    .then(async (response) => {
                        setPaymentMethods(response);
                        setLoadingMethods(false);
                    })
                    .catch((e) => {
                        setException(e.message);
                        setLoadingMethods(false);
                    });
            }

        }

        fetchPackages();
        fetchPaymentMethods();

        return () => {
            controller.abort();
        };
    }, [isOpen]);


    const clean = async () => {
        setSelectedPackage(null)
        setSelectedMethod(null)
        setPaymentDetail(false)
        setPaymentStep(STEP_PACKAGE)
    };



    const confirm = async () => {
        setLoading(true);

        postElement(getWebPathParam([PACKAGE, selectedPackage.id ,PAYMENT_METHOD, selectedMethod.id]), {success_url: url, cancel_url: window.location.href})
            .then(async (response) => {
                if(response.payment_url){
                    window.location.href  = response.payment_url;
                }else{
                    setPaymentDetail(response);
                    setPaymentStep(STEP_OFFLINE_PAYMENT);
                    setLoading(false);
                }
            })
            .catch((e) => {
                setException(e.message);
                setLoading(false);
            });
    };



    return (
        <Layout title={t("app.common.buy_credit_title")} setIsOpen={async (r) => {
            await clean()
            setIsOpen(r);
        }} isOpen={isOpen}>



                {
                    paymentStep === STEP_PACKAGE && (
                        <>
                            <div className="w-full p-2 shadow-inner">
                                <div className="mx-auto w-full max-w-md">

                                    <LoaderWrapper isLoading={isLoadingPackages}>

                                        <RadioGroup value={selectedPackage} onChange={setSelectedPackage}>

                                            <div className="space-y-2 h-80 overflow-y-scroll">
                                                {packages.sort((a, b) => a.credit - b.credit).map((plan) => (

                                                    <RadioGroup.Option
                                                        key={plan.id}
                                                        value={plan}
                                                        className={({ active, checked }) =>
                                                            `${active ? 'ring-2 ring-white ring-opacity-60 ring-offset-2 ring-offset-sky-300': ''} 
                                                         ${checked ? 'border border-red-600' : 'bg-white'} 
                                                         relative flex cursor-pointer rounded-lg px-2 py-3 shadow-sm focus:outline-none border border-grey-300`
                                                        }
                                                    >
                                                        {({ active, checked }) => (
                                                            <>
                                                                <div className="flex w-full items-center justify-between">
                                                                    <div className="flex items-center">
                                                                        <div className="text-sm">
                                                                            <RadioGroup.Label
                                                                                as="p"
                                                                                className={'font-medium text-red-600'}
                                                                            >
                                                                                {plan.title}
                                                                            </RadioGroup.Label>
                                                                            <RadioGroup.Description
                                                                                as="span"
                                                                                className={'inline text-gray-500'}
                                                                            >
                                                                                <span>{plan.description}</span>
                                                                            </RadioGroup.Description>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <p className={'font-medium text-gray-900 mr-3'}>
                                                                            <NumberFormat
                                                                                value={plan.total}
                                                                                displayType={"text"}
                                                                                decimalScale={2}
                                                                                thousandSeparator={"."}
                                                                                decimalSeparator={","}
                                                                                prefix={'€ '}
                                                                            />
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                    </RadioGroup.Option>
                                                ))}
                                            </div>
                                        </RadioGroup>


                                    </LoaderWrapper>






                                </div>
                            </div>


                            <div className="p-4 flex before:flex-1 items-center justify-between ">
                                <PrimaryButton
                                    isLoading={false}
                                    disabled={selectedPackage === null}
                                    label={t("app.common.next")}
                                    onClick={() => {
                                        setPaymentStep(STEP_PAYMENT_METHOD)
                                    }}
                                />
                            </div>

                        </>
                    )
                }

                {
                    paymentStep === STEP_PAYMENT_METHOD && (
                        <>
                            <div className="w-full p-2 shadow-inner">
                                <div className="mx-auto w-full max-w-md">

                                    <LoaderWrapper isLoading={isLoadingMethods}>

                                        <RadioGroup value={selectedMethod} onChange={setSelectedMethod}>

                                            <div className="space-y-2">
                                                {paymentMethods.map((plan) => (

                                                    <RadioGroup.Option
                                                        key={plan.id}
                                                        value={plan}
                                                        className={({ active, checked }) =>
                                                            `${active ? 'ring-2 ring-white ring-opacity-60 ring-offset-2 ring-offset-sky-300': ''} 
                                                         ${checked ? 'border border-red-600' : 'bg-white'} 
                                                         relative flex cursor-pointer rounded-lg px-2 py-3 shadow-sm focus:outline-none border border-grey-300`
                                                        }
                                                    >
                                                        {({ active, checked }) => (
                                                            <>
                                                                <div className="flex w-full items-center justify-between">
                                                                    <div className="flex items-center">
                                                                        <div className="text-sm">
                                                                            <RadioGroup.Label
                                                                                as="p"
                                                                                className={'font-medium text-red-600'}
                                                                            >
                                                                                {plan.title}
                                                                            </RadioGroup.Label>
                                                                            <RadioGroup.Description
                                                                                as="span"
                                                                                className={'inline text-gray-500'}
                                                                            >
                                                                                <span>{plan.description}</span>
                                                                            </RadioGroup.Description>
                                                                        </div>
                                                                    </div>
                                                                    <div>

                                                                        {
                                                                            plan.picture && (
                                                                                <img alt={''} className="mr-3 w-16" src={plan.picture.reference} />
                                                                            )
                                                                        }

                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                    </RadioGroup.Option>
                                                ))}
                                            </div>
                                        </RadioGroup>


                                    </LoaderWrapper>






                                </div>
                            </div>


                            {/* Warning */}
                            {exception && <Warning message={exception} />}


                            <div className="p-4 flex items-center justify-between ">

                                <div className="text-sm underline inline-flex items-center cursor-pointer"
                                     onClick={() => {
                                         setPaymentStep(STEP_PACKAGE)
                                     }}
                                >
                                    <ArrowLeftIcon className="h-4 w-4 mr-2" aria-hidden="true" />{" "}
                                    {t("app.common.back")}
                                </div>


                                <PrimaryButton
                                    isLoading={isLoading}
                                    label={t("app.common.confirm")}
                                    disabled={selectedMethod === null}
                                    onClick={async () => {
                                        await confirm();
                                    }}
                                />
                            </div>

                        </>
                    )
                }

                {
                    paymentStep === STEP_OFFLINE_PAYMENT && (
                        <>
                            <div className="w-full p-4 shadow-inner">
                                <div className="mx-auto w-full max-w-md">


                                    <h2 className="text-2xl font-bold tracking-tight text-green-600">
                                        {t("app.payment.pending_title")}
                                    </h2>

                                    <p className="mx-auto mt-2 mb-2 max-w-xl text-md  text-gray-600">
                                        {t("app.payment.pending_description")}
                                    </p>

                                    <div className={"mx-aut mb-2 max-w-xl text-md  text-gray-600"} dangerouslySetInnerHTML={{__html: paymentDetail.value}} />
                                </div>
                            </div>


                            <div className="p-4 flex items-center before:flex-1 justify-between ">
                                <PrimaryButton
                                    isLoading={false}
                                    label={t("app.common.close")}
                                    onClick={() => {
                                        clean()
                                        setIsOpen(false);
                                    }}
                                />
                            </div>

                        </>
                    )
                }





        </Layout>
    )
}


const Layout = ({title, isOpen, setIsOpen, children}) => {


    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog
                as="div"
                className="fixed inset-0 z-10 overflow-y-auto"
                onClose={setIsOpen}
            >
                <div className="min-h-screen px-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-900 bg-opacity-30 z-5 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="inline-block h-screen align-middle"
                        aria-hidden="true"
                    >&#8203;</span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div className="inline-block w-full max-w-md my-8 text-left align-middle transition-all transform bg-white shadow-xl">

                            <div className="flex justify-between border-b p-4 items-center">
                                <div>
                                    <h3 className="text-lg font-medium leading-6 text-blue-1000">
                                        {title}
                                    </h3>
                                </div>
                                <div>
                                    <button className="bg-white border-0" onClick={() => {setIsOpen(false)}}><XIcon className="w-4 h-4 fill-current flex-shrink-0 text-blue-1000" /></button>
                                </div>
                            </div>


                            {children}


                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    )

}

export default BuyPopup;
