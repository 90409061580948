import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./resources/style/index.css";
import Main from "./routes/main";
import { store } from "./reducers/rootReducer";

import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";

import "./i18n";
import Loading from "./components/layout/loading";
import {injectStore} from "./utils/apiUtils";
import { injectStore as injectStoreGame } from "./utils/gameApiUtils";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";


Sentry.init({
    dsn: "https://9343209f7955048cb93d9e500f4c189a@o4505945974571008.ingest.sentry.io/4505945983025152",
    integrations: [new Integrations.BrowserTracing()],
    // We recommend adjusting this value in production.
    tracesSampleRate: 1.0,
});

injectStore(store);
injectStoreGame(store);



ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Suspense fallback={<Loading />}>
          <Main />
        </Suspense>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
