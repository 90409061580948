import {serialize} from "../utils/converter";
import API from "../utils/apiUtils";
import API_GAME from "../utils/gameApiUtils";

export const deleteElement = (endpoint) => API.delete(endpoint);
export const putElement = (endpoint, data = false) => API.put(endpoint, data ? data : {});
export const postElement = (endpoint, data = false) => API.post(endpoint, data ? data : {});
export const getElement = (endpoint, signal = null, params = null) => API.get(endpoint + (params ? serialize(params)  : ''), signal ? {signal: signal} : {});


export const deleteGameElement = (endpoint) => API_GAME.delete(endpoint);
export const putGameElement = (endpoint, data = false) => API_GAME.put(endpoint, data ? data : {});
export const postGameElement = (endpoint, data = false) => API_GAME.post(endpoint, data ? data : {});
export const getGameElement = (endpoint, signal = null, params = null) => API_GAME.get(endpoint + (params ? serialize(params) : ''), signal ? { signal: signal } : {});
