import { useState } from 'react'
import {AnimatePresence, motion} from 'framer-motion/dist/framer-motion'

export function NavLinks() {
  let [hoveredIndex, setHoveredIndex] = useState(null)

  return [
    ['Nuove Uscite', 'https://www.minigoal.it/#last-minigoal'],
    ['Chi siamo', 'https://www.minigoal.it/#about-us'],
    ['Contattaci', 'https://www.minigoal.it/#contact-us'],
    ['FAQs', 'https://www.minigoal.it/#faqs'],
  ].map(([label, href], index) => (
    <a
      key={label}
      href={href}
      className="relative -mx-3 -my-2 rounded-lg px-3 py-2 text-sm text-gray-700 transition-colors delay-150 hover:text-gray-900 hover:delay-[0ms]"
      onMouseEnter={() => setHoveredIndex(index)}
      onMouseLeave={() => setHoveredIndex(null)}
    >
      <AnimatePresence>
        {hoveredIndex === index && (
          <motion.span
            className="absolute inset-0 rounded-lg bg-gray-100"
            layoutId="hoverBackground"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.15 } }}
            exit={{
              opacity: 0,
              transition: { duration: 0.15, delay: 0.2 },
            }}
          />
        )}
      </AnimatePresence>
      <span className="relative z-10">{label}</span>
    </a>
  ))
}
