
// User
export const GET_LOGGED_USER = "user/me";
export const USER = "user";
export const ME = "me";
export const DASHBOARD = "dashboard";
export const WIDGETS = "widgets";
export const WIDGET = "widget";
export const WIDGET_TYPES = "widget_types";
export const WIDGET_POSITION = "widget_position";
export const POINT = "point";
export const CLICK = "click";
export const LEVEL = "level";
export const RESET_POINTS = "reset_points";


// verify

// reset password
export const RESET_PASSWORD = "reset_password";

// Config
export const GET_COUNTRIES = "countries";
export const GET_UNITS = "units";
export const GET_WEBHOOK_EVENTS = "webhook_events";
export const GET_CITIES= "cities";
export const GET_PHONE_PREFIXES = "phone_prefixes";
export const GET_PORTS = "ports";
export const GET_VESSELS = "vessels";
export const GET_CURRENCIES = "currencies";
export const GET_DELIVERY_TYPES = "delivery_types";
export const GET_SHIPPING_TYPES = "shipping_types";
export const GET_TERM_TYPES = "term_types";
export const GET_SHIPPING_LINES = "shipping_lines";
export const GET_DOCUMENT_TYPES = "document_types";
export const GET_EVENT_TYPES = "event_types";
export const GET_CONTAINER_TYPES = "container_types";
export const GET_SIGNED_REQUESTS = "signed_requests";
export const GET_PERMISSIONS = "permissions";
export const GET_PACKAGING_TYPES = "packaging_types";
export const GET_NOTIFICATION_TYPES = "notification_types";
export const GET_EMAIL_PROVIDERS = "email_providers";
export const GET_LANGUAGES = "languages";

// Company
export const COMPANIES = "companies";
export const COMPANY = "company";
export const USERS = "users";
export const PREFIXES = "prefixes";
export const PREFIX = "prefix";
export const WEBHOOKS = "webhooks";
export const WEBHOOK = "webhook";
export const LOGS = "logs";
// Customers
export const CUSTOMERS = "customers";
export const CUSTOMER = "customer";
export const SEND_CONNECTION = "send_connection";
export const CANCEL_CONNECTION = "cancel_request";
export const RESTORE = "restore";
export const ARCHIVE = "archive";

// Orders
export const ORDERS = "orders";
export const ORDER = "order";
export const ACTIVE_ORDERS = "active_orders";
export const ACTIVE_ORDER = "active_order";
export const ORDER_PRODUCTS = "order_products";
export const ORDER_PRODUCT = "order_product";

export const QUOTATIONS = "quotations";
export const QUOTATION = "quotation";
export const STATUS = "status";
export const CONFIRMED = "confirmed";


// Forwarders
export const FORWARDERS = "forwarders";
export const FORWARDER = "forwarder";
export const FORWARDER_EMAIL = "forwarder_email";
export const FORWARDER_EMAILS = "forwarder_emails";

// tasks
export const TASKS = "tasks";
export const TASK = "task";
export const FILE = "file";

// Supplier
export const SUPPLIERS = "suppliers";
export const SUPPLIER = "supplier";

// Shippings
export const SHIPPINGS = "shippings";
export const SHIPPING = "shipping";
export const TRACKING = "tracking";
export const SHIPPING_IMPORTER = "shipping_importer";
export const IMPORTER = "importer";

// Container
export const CONTAINERS = "containers";
export const CONTAINER = "container";
export const GENERATE_PACKING_LIST = "generate_packing_list";
export const GENERATE_PACKING_LIST_SIMULATION = "generate_packing_list_simulation";
export const MEDIAS = "medias";
export const MEDIA = "media";
export const CONTAINER_PRODUCT = "container_product";
export const CONTAINER_PRODUCTS = "container_products";
export const PACKINGS = "packings";
export const PACKING = "packing";
export const AUTOMATIC = "automatic";

export const GROUPS = "groups";
export const GROUP = "group";
export const SUB_GROUP = "sub_group";
export const LOAD_ALL = "load_all";
export const CLONE = "clone";

// pallets
export const PALLETS = "pallets";
export const PALLET = "pallet";

// Document
export const PACKAGINGS = "packagings";
export const PACKAGING = "packaging";


// Document
export const DOCUMENTS = "documents";
export const DOCUMENT = "document";
export const DOCUMENT_SEND_NOTIFICATION = "document_send_notification";

// Event
export const EVENTS = "events";
export const EVENT = "event";
export const EVENT_TYPE = "event_type";

// Catalog
export const PRODUCTS = "products";
export const CATALOG_PRODUCTS = "catalog_products";
export const CATALOG = "catalog";
export const PRODUCT = "product";
export const PRICE_HISTORY = "price_history";
export const SELLING_HISTORY = "selling_history";
export const PRODUCT_POSITIONS = "product_positions";
export const DIMENSION = "dimension";
export const DIMENSIONS = "dimensions";
export const CATEGORIES = "categories";
export const CATEGORY = "category";
export const BRANDS = "brands";
export const BRAND = "brand";
export const IMAGE = "image";
export const TRANSLATION = "translation";
export const TRANSLATIONS = "translations";
export const COMPANY_SUPPLIERS = "company_suppliers";
export const COMPANY_SUPPLIER = "company_supplier";
export const MANUFACTURES = "manufactures";
export const MANUFACTURE = "manufacture";

export const PRICE_HISTORIES = "price_histories";
export const DATA_PRICE_HISTORIES = "data_price_histories";


// import
export const IMPORT = "import";
export const IMPORTS = "imports";
export const IMPORT_MODEL = "import_model";
export const IMPORT_MODELS = "import_models";

// notification
export const NOTIFICATIONS = "notifications";
export const NOTIFICATION_UNREAD = "notification_unread";
export const NOTIFICATION_READ = "notification_read";

// Connection
export const CONNECTION_VERIFY = "connection_verify";
export const COMPANY_CONNECTION_VERIFY = "company_connection_verify";
export const USER_CONNECTION_VERIFY = "user_connection_verify";
export const CONNECTIONS = "connections";
export const CONNECTION = "connection";
export const VERIFY = "verify";
export const USER_CONNECTIONS = "user_connections";
export const USER_CONNECTION = "user_connection";
export const USER_PERMISSIONS = "user_permissions";
export const USER_PERMISSION = "user_permission";
export const CONNECTION_CATEGORIES = "connection_categories";
export const CONNECTION_CATEGORY = "connection_category";
export const NOTIFICATION = "notification";

// warehouse
export const WAREHOUSE = "warehouse";
export const WAREHOUSES = "warehouses";


// report
export const REPORT = "report";
export const REPORT_TYPES = "report_types";
export const REPORT_TYPE = "report_type";
export const REPORT_GENERATE = "report_generate";
export const REPORT_CONFIRM = "report_confirm";
export const DOCUMENT_DOWNLAOD = "document_download";

// warehouse
export const SUBSCRIPTIONS = "subscriptions";
export const SUBSCRIPTION = "subscription";
export const SUBSCRIPTION_PAYMENT = "subscription_payment";
export const SUBSCRIPTION_CHANGE = "subscription_change";
export const SUBSCRIPTION_CONFIRM = "subscription_confirm";
export const SUBSCRIPTION_DOWNGRADE_CHECK = "subscription_downgrade_check";
export const CHECKOUT = "checkout";
export const PAYMENT_INTENT = "payment_intent";
export const OFFLINE_SECRET = "offline_secret";

export const SUBSCRIPTION_CANCEL = "subscription_cancel";
export const SUBSCRIPTION_ACTIVE = "subscription_active";
export const SUBSCRIPTION_PAY = "subscription_pay";
export const PROPOSAL = "proposal";
export const SUBSCRIPTION_BILLING = "subscription_billing";
export const SUBSCRIPTION_USAGE = "subscription_usage";

// email_provider
export const EMAIL_PROVIDER = "email_provider";
export const EMAIL = "email";
export const EMAIL_LIST = "mail_list";
export const SEND_EMAIL = "send_mail";
export const EMAIL_VERIFY = "email_verify";

