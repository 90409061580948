import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import {getElement} from "../../api/base-api";
import {getWebPathParam} from "../../utils/converter";
import {MAGAZINES, USER} from "../endpoints";
import LoaderWrapper from "../../components/loader/loader-wrapper";
import {useParams} from "react-router-dom";
import MagazineCard from "../../components/pdf/magazine-card";
import {useSelector} from "react-redux";
import EmptyState from "../../components/layout/empty-state";
import noMinigoal from "../../resources/images/no-minigoal.png";

const MyMagazine = () => {
    const { t } = useTranslation();
    let {id}  = useParams();
    const { detail } = useSelector((state) => state.user );


    const [magazines, setMagazines] = useState([]);
    const [isLoading, setLoading] = useState(false);
    // eslint-disable-next-line
    const [exception, setException] = useState(false);
    const [refreshData, setRefreshData] = useState(null);



    function fetchMagazines(signal = null) {

        if(refreshData === null){
            setLoading(true);
        }
        getElement(getWebPathParam([USER, detail.id, MAGAZINES]))
            .then(async (response) => {
                setMagazines(response.user_magazines);
                setLoading(false);
                setRefreshData(false);
            })
            .catch((e) => {
                setException(e.message);
                setLoading(false);
            });
    }

    useEffect(() => {

        const controller = new AbortController();
        const signal = controller.signal;

        fetchMagazines(signal);

        return () => {
            controller.abort();
        };
        // eslint-disable-next-line
    }, [id]);


    return (
        <div className="py-12">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                <div className="">

                    <LoaderWrapper isLoading={isLoading}>

                        {
                            magazines.length > 0 ? (
                                <>
                                    <h3 className="text-base font-semibold leading-6 text-gray-900">
                                        {t("app.pages.my_magazine_title")}
                                    </h3>
                                    <p className="mt-2 max-w-4xl text-sm text-gray-500 border-b border-gray-200 pb-5">
                                        {t("app.pages.my_magazine_description")}
                                    </p>

                                    <ul
                                        className="mx-auto mt-20 grid max-w-2xl grid-cols-2 gap-y-20 gap-x-6 sm:grid-cols-3 lg:mx-0 md:max-w-none lg:grid-cols-4 sm:gap-y-20 sm:gap-x-8 md:gap-x-10 lg:gap-x-12"
                                    >
                                        {magazines.map((item) => (
                                            <li key={item.magazine.id}>
                                                <MagazineCard
                                                    image={item?.magazine?.picture?.reference ?? false}
                                                    title={item.magazine.title}
                                                    description={item.magazine.description}
                                                    magazineDetail={item.magazine}
                                                    onRefreshData={(r) => { setRefreshData(r); fetchMagazines();}}
                                                />
                                            </li>
                                        ))}
                                    </ul>
                                </>
                            ) : (
                                <EmptyState
                                    img={noMinigoal}
                                    title={t("app.empty_state.my_magazine_title")}
                                    description={t("app.empty_state.my_magazine_description")}
                                />
                            )
                        }
                    </LoaderWrapper>

                </div>
            </div>
        </div>
    );
};

export default MyMagazine;
