// gameSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getGameElement, postGameElement } from '../api/base-api'; // Assumendo che fetchUserPoints sia un'azione asincrona
import { getWebPathParam } from '../utils/converter';
import { USER } from '../routes/endpoints';
import { CLICK, POINT, RESET_POINTS } from '../api/endpoints';

export const fetchUserPointsAsync = createAsyncThunk(
    'game/fetchUserPoints',
    async () => {
        const response = await getGameElement(getWebPathParam([USER, RESET_POINTS]));
        return response; // Aggiorna in base al formato di risposta della tua API
    }
);


export const handleTapAsync = createAsyncThunk(
    'game/handleTap',
    async (data = null) => {
        const response = await postGameElement(getWebPathParam([POINT, CLICK]), data);
        return response; // Aggiorna in base al formato di risposta della tua API
    }
);

const gameSlice = createSlice({
    name: 'game',
    initialState: {
        gasCounter: 0,
        gasLimitCounter: 0,
        incrementalGas: 0,
        currentPoint: 0,
        pointPerTap: 0,
        nextLevelPoint: 0,
        previousLevel: false,
        curentLevel: false,
        userGameDetails: false,
        startTime: 1

    },
    reducers: {
        decrementGasCounter: (state, action) => {
            state.gasCounter = Math.max(0,  - action.payload);
        },
        setIncrementalGas: (state, action) => {
            state.incrementalGas = action.payload;
        },
        setGasCounter: (state, action) => {
            state.gasCounter = action.payload;
        },
        setGasLimitCounter: (state, action) => {
            state.gasLimitCounter = action.payload;
        },
        setCurrentPoint: (state, action) => {
            state.currentPoint = action.payload;
        },
        updateGameData: (state, action) => {
            state.gasLimitCounter = action.payload.boster_limit;
            state.gasLimitCounter = action.payload.boster_limit;
            state.incrementalGas = action.payload.boster_rest_per_secend;
            state.nextLevelPoint = action.payload.next_level;
            state.pointPerTap = action.payload.point_goal;
            state.startTime = action.payload.start_time;
            state.curentLevel = action.payload;
        },
        updatePreviousLevel: (state, action) => {
            state.previousLevel = action.payload;
        },
        onSetUserGameDetails: (state, action) => {
            state.userGameDetails = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchUserPointsAsync.fulfilled, (state, action) => {
            state.gasCounter = action.payload.reset_point; // Aggiorna in base al formato di risposta della tua API
        });
        builder.addCase(handleTapAsync.fulfilled, (state, action) => {
            state.currentPoint = action.payload.points;
            state.gasCounter = action.payload.reset_point;
            state.curentLevel = action.payload.level;
            state.gasLimitCounter = action.payload.level.boster_limit;
            state.gasLimitCounter = action.payload.level.boster_limit;
            state.incrementalGas = action.payload.level.boster_rest_per_secend;
            state.nextLevelPoint = action.payload.level.next_level;
            state.pointPerTap = action.payload.level.point_goal;
            state.startTime = action.payload.level.start_time;
        });
    },
});

export const { decrementGasCounter, setIncrementalGas, setGasCounter, setGasLimitCounter, setCurrentPoint, updateGameData, updatePreviousLevel, onSetUserGameDetails } = gameSlice.actions;
export default gameSlice.reducer;
