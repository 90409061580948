import axios from "axios";
import {clearToken} from "./tokenUtil";

let store;
export const injectStore = _store => {
    store = _store
};

function generateUniqueId() {
    // Generate a pseudo-random unique identifier.
    return '_' + Math.random().toString(36).substr(2, 9);
}

// Try to get the unique ID from localStorage.
let uniqueId = localStorage.getItem('uniqueId');
// If not found, generate a new one and store it in localStorage.
if (!uniqueId) {
    uniqueId = generateUniqueId();
    localStorage.setItem('uniqueId', uniqueId);
}


const API_GAME = axios.create({
    baseURL: "https://game.skemasoft.com/api",
    responseType: "json",
    timeout: 20000,
    headers: {
        "Content-Type": "application/json"
    },
});

API_GAME.interceptors.request.use(async function (req) {
    if (store.getState().user.game_token !== null) {
        req.headers.Authorization = 'Bearer '+store.getState().user.game_token;
    }
    return req;
});


API_GAME.interceptors.response.use(
    function (response) {
        //console.log(response);
        return response.data.data;
    },function (error) {
        if (error.response){



            let status = 0;
            let message = '';
            if (error.response != null && error.response.data != null) {
                status = error.response.data.code;
                message = error.response.data.message;
                if(error.response.data.validation != null){
                    message = error.response.data.validation[0];
                }
            }

            if(store.getState().user.token && status === 401){
                clearToken();
                localStorage.clear();
                window.location.reload();
            }

            return Promise.reject({ code: status, message: message });
        }else if(error.request){
            //console.log(error.request);
        }else if(error.message){
            return Promise.reject({ code: 0, message: 'Request Canceled' });
        }

    }
);

export default API_GAME;