import React, {useState} from "react";
import {useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import InputText from "../../components/form/input-text";
import {emailRegex} from "../../constants/regex";
import {Success, Warning} from "../../components/alert/banner";
import InputPrimarySubmit from "../../components/form/input-primay-submit";
import {useTranslation} from "react-i18next";
import {Navigate} from "react-router-dom";
import {INDEX, RESET_PASSWORD} from "../endpoints";
import {postElement} from "../../api/base-api";
import {getWebPathParam} from "../../utils/converter";

const ResetPasswordRequest = () => {
  const { t } = useTranslation();

  const { register, handleSubmit, formState: { errors } } = useForm();

  // USER API
  const [isLoading, setLoading] = useState(false);
  const [exception, setException] = useState(false);
  const [isRequestSend, setRequestSend] = useState(false);

  const { isAuthenticated } = useSelector((state) => state.user);
  if(isAuthenticated){
    return (<Navigate to={INDEX} />);
  }


  const onSubmit = (data) => {
    setLoading(true);
    setException(false);
    postElement(getWebPathParam([RESET_PASSWORD]), data)
        .then(response => {
          setRequestSend(response.status === "ok");
          setLoading(false);
        }).catch(e => {
      setException(e.message);
      setLoading(false);
    });
  };



  return(
      <main className="bg-white py-16">

        <div className="max-w-lg mx-auto px-4 py-8 w-3/4">
          <h1 className="text-3xl text-gray-800 font-bold mb-6">{t("app.reset_password.title")}</h1>
          {/* Form */}
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="space-y-4">
              <InputText
                  id={'email'}
                  type={'email'}
                  label={t("app.form.email")}
                  errors={errors.email}
                  input={{...register("email", { required: true, pattern: emailRegex })}}
              />

            </div>



            {/* Warning */}
            {exception && (
                <Warning
                    message={exception}
                />
            )}

            {isRequestSend && (
                <Success
                    message={t('app.reset_password.success_message')}
                />
            )}

            <div className="flex items-center justify-between mt-6">
              <InputPrimarySubmit
                  isLoading={isLoading}
                  label={t('app.reset_password.name')}
                  isFullWith={true}
              />
            </div>
          </form>


        </div>


      </main>
  );
};

export default ResetPasswordRequest;
