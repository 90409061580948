import React, { useEffect } from "react";
import {  useDispatch } from "react-redux";
import { postElement } from "../../../api/base-api";
import { getWebPathParam } from "../../../utils/converter";
import { AUTH, GAME, GAME_API, PLAY } from "../../endpoints";
import { loginUser, onboardGameToken } from "../../../reducers/userReducer";
import {  useLocation, useNavigate } from "react-router-dom";
import Loading from "../../../components/layout/loading";



const GameBridge = () => {
    const dispatch = useDispatch();
    let navigate = useNavigate();

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    const query = useQuery();
    const token = query.get('token');




    function AuthGame(token) {
        postElement(getWebPathParam([GAME_API, AUTH]), { bridge_token: token })
            .then(async (response) => {
                await dispatch(loginUser(response));
                await dispatch(onboardGameToken(response.game_token));
                navigate(getWebPathParam([GAME, PLAY]));
            })
            .catch((e) => {
            });
    }


    useEffect(() => {
        if (token){
            AuthGame(token);
        }
        // eslint-disable-next-line
    }, [token]); 



    return (
        <>
             <Loading />;
        </>
      
    );
};



export default GameBridge;
