/*export const setToken = token => localStorage.setItem("token", token);
export const getToken = () =>  localStorage.getItem('token') ? localStorage.getItem('token') : null ;
export const clearToken = () => localStorage.removeItem('token');
export const isAuthenticated = () => localStorage.getItem('token') !== null;*/

import {setCookie, getCookie, eraseCookie} from '../constants/cookie';

export const setToken = token => setCookie('token', token, 10);
export const getToken = () =>  getCookie('token');
export const clearToken = () => eraseCookie('token');
export const isAuthenticated = () => getCookie('token') !== null;

export const setGameToken = token => setCookie('game_token', token, 10);
export const getGameToken = () => getCookie('game_token');
export const clearGameToken = () => eraseCookie('game_token');
export const isGameAuthenticated = () => getCookie('game_token') !== null;
