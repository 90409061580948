import React, { useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import GameSlideAlert from "../../../components/game/gamse-slide-alert";
import { Controller, useForm } from "react-hook-form";
import InputTextBlack from "../../../components/form/input-text-black";
import InputSelect from "../../../components/form/input-select";
import { FOOTBALL_POSITIONS, UNDER_EDGES } from "../../../constants/config";


const onboardSlides = [
    {
        image: `${process.env.PUBLIC_URL}/game/trophy.png`,
        title: 'Benvenuto a "Vota il migliore!"',
        description: 'Entra nel mondo di "Vota il migliore!", dove la competizione incontra il divertimento. Scopri come puoi diventare il protagonista della stagione 2024/2025 votando il tuo "Top Player" preferito.',
    },
    {
        image: `${process.env.PUBLIC_URL}/game/onbording-2.png`,
        title: 'Come Funziona il Gioco',
        description: 'È semplice! Clicca sul bottone ogni giorno per accumulare punti. Più punti raccogli, maggiori sono le tue possibilità di vincere premi fantastici. Inizia ora e scopri quanto è facile giocare!',
    },
    {
        image: `${process.env.PUBLIC_URL}/game/onbording-3.png`,
        title: 'Accumula e Usa i Punti',
        description: 'Accumula punti cliccando ogni giorno. Usa i tuoi punti per votare i tuoi giocatori preferiti e riscattare premi esclusivi. Più giochi, più vinci!',
    },
    {
        image: `${process.env.PUBLIC_URL}/game/onbording-4.png`,
        title: 'Premi e Sorprese',
        description: 'Ogni punto guadagnato ti avvicina a premi incredibili. Scopri le sorprese che abbiamo preparato per te lungo il percorso. Non perdere l\'occasione di essere premiato!',
    },
    {
        image: `${process.env.PUBLIC_URL}/game/onbording-5.png`,
        title: "L'Evento Finale della Stagione",
        description: "Alla fine della stagione, partecipa all'evento esclusivo dove i 'Top Players' e i giocatori più attivi verranno premiati. Unisciti a noi in una celebrazione unica dello sport!",
    },
    {
        image: `${process.env.PUBLIC_URL}/game/onbording-6.png`,
        title: "Resta Aggiornato",
        description: "Non perdere le novità! Seguici sui social media per rimanere sempre aggiornato sulle iniziative e le sorprese del gioco.",
    },
    {
        image: `${process.env.PUBLIC_URL}/game/onbording-7.png`,
        title: "Inizia a Giocare Ora!",
        description: "Sei pronto a giocare, accumulare punti e vincere? Clicca il bottone e inizia subito il tuo viaggio verso la vittoria. Divertiti e diventa il campione della stagione!",
    }
];

const GameOnboarding = ({ onSaveData, isLoading }) => {
    const [isOpenPanel, setOpenPanel] = useState(false);
    const [step, setStep] = useState(1);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [animationClass, setAnimationClass] = useState('');
    const touchStartX = useRef(0);
    const touchEndX = useRef(0);


    const goToSlide = (index, direction) => {
        const exitClass = direction === 'left' ? 'slide-exit-left' : 'slide-exit-right';
        const enterClass = direction === 'left' ? 'slide-enter-right' : 'slide-enter-left';

        setAnimationClass(exitClass);
        setTimeout(() => {
            setCurrentSlide(index);
            setAnimationClass(enterClass);
        }, 500); // match this duration with your CSS animation duration
    };

    const nextSlide = () => {
        goToSlide((currentSlide + 1) % onboardSlides.length, 'left');
    };

    const prevSlide = () => {
        goToSlide((currentSlide - 1 + onboardSlides.length) % onboardSlides.length, 'right');
    };

    const handleTouchStart = (e) => {
        touchStartX.current = e.touches[0].clientX;
    };

    const handleTouchMove = (e) => {
        touchEndX.current = e.touches[0].clientX;
    };

    const handleTouchEnd = () => {
        const deltaX = touchStartX.current - touchEndX.current;

        if (deltaX > 50) {
            // Swiped left
            nextSlide();
        } else if (deltaX < -50) {
            // Swiped right
            prevSlide();
        }
    };


    const { register, handleSubmit, control, formState: { errors } } = useForm({ mode: 'onBlur' });

    const onSubmit = async (data) => {
        // Your form submission logic
        if (data) {
            if (data['under_edges']) {
                data['underage'] = data['under_edges'].value;
                delete data['under_edges'];
            } else {
                delete data['under_edges'];
            }

            if (data['position']) {
                data['player_role'] = data['position'].value;
                delete data['position'];
            } else {
                delete data['position'];
            }

            if (data['team'] === '') {
                delete data['team'];
            }

            if (data['invitation_code'] === '') {
                delete data['invitation_code'];
            }
        }


        onSaveData(data);
    };

    return (
        <>
            <div className="py-4">
                <div className="mx-auto max-w-7xl px-6">
                    <div className="w-full max-w-2xl mx-auto">
                        <div className="flex flex-col w-full mx-auto">
                            <div 
                                className="relative" 
                                onTouchStart={handleTouchStart}
                                onTouchMove={handleTouchMove}
                                onTouchEnd={handleTouchEnd}
                            >
                                <div className="relative overflow-hidden">
                                    <img
                                        src={onboardSlides[currentSlide].image}
                                        alt={onboardSlides[currentSlide].title}
                                        className={`w-full h-auto object-cover ${animationClass}`}
                                    />
                                </div>

                                <div className="text-center mt-4 h-36">
                                    <h3 className={`text-2xl font-semibold leading-6 text-white ${animationClass}`}>
                                        {onboardSlides[currentSlide].title}
                                    </h3>
                                    <p className={`mt-5 whitespace-pre-line line-clamp-3 text-sm leading-6 text-white ${animationClass}`}>
                                        {onboardSlides[currentSlide].description}
                                    </p>
                                </div>

                                <div className="flex justify-center mt-8">
                                    {onboardSlides.map((_, index) => (
                                        <button
                                            key={index}
                                            onClick={() => goToSlide(index)}
                                            className={`w-3 h-3 mx-1 rounded-full ${currentSlide === index ? 'bg-gray-800' : 'bg-gray-400'}`}
                                        />
                                    ))}
                                </div>
                            </div>

                            <InputPrimarySubmit
                                onClick={() => {
                                    setOpenPanel(true);
                                }}
                                isLoading={false}
                                label={'Vai al gioco!'}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <GameSlideAlert
                isOpen={isOpenPanel}
                setIsOpen={(r) => {
                    setOpenPanel(r);
                    setStep(1);
                }}
            >
                <div className="py-4">
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">

                        <form className="md:col-span-2  py-6 px-4  rounded-md shadow-md" onSubmit={handleSubmit(onSubmit)}>

                            {
                                step === 1 && (
                                    <>

                                        <div className="mb-5">
                                            <InputTextBlack
                                                id={'team'}
                                                type={'text'}
                                                errors={errors.team}
                                                input={{ ...register("team", { required: false }) }}
                                                placeholder={'Inserisci squadra di appartenenza'}
                                                label={'Squadra di appartenenza (opzionale)'}
                                            />
                                        </div>

                                        <div className="mb-5">
                                            <Controller
                                                name="under_edges"
                                                rules={{ required: false }}
                                                control={control}
                                                render={({
                                                    field: { onChange, value, name },
                                                    fieldState: { error },
                                                }) => (
                                                    <InputSelect
                                                        label={'Categoria/Leva (opzionale)'}
                                                        placeholder={'Seleziona categoria/leva'}
                                                        name={name}
                                                        options={UNDER_EDGES}
                                                        value={value}
                                                        isInsideTarget={true}
                                                        onChange={(e) => {
                                                            onChange(e);
                                                        }}
                                                        isLoading={false}
                                                        isDisabled={false}
                                                        isClearable={true}
                                                        errors={error}
                                                    />
                                                )}
                                            />
                                        </div>

                                        <div className="mb-5">
                                            <Controller
                                                name="position"
                                                rules={{ required: false }}
                                                control={control}
                                                render={({
                                                    field: { onChange, value, name },
                                                    fieldState: { error },
                                                }) => (
                                                    <InputSelect
                                                        label={'Ruolo (opzionale)'}
                                                        placeholder={'Seleziona ruolo'}
                                                        name={name}
                                                        options={FOOTBALL_POSITIONS}
                                                        value={value}
                                                        isInsideTarget={true}
                                                        onChange={(e) => {
                                                            onChange(e);
                                                        }}
                                                        isLoading={false}
                                                        isDisabled={false}
                                                        isClearable={true}
                                                        errors={error}
                                                    />
                                                )}
                                            />
                                        </div>

                                        <div
                                            onClick={() => {
                                                setStep(2)
                                            }}
                                            className={`w-full mt-10 cursor-pointer disabled:opacity-70 disabled:cursor-not-allowed flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-bold  text-white bg-gray-800`}
                                        >Conferma & procedi
                                        </div>

                                    </>
                                )
                            }



                            {
                                step === 2 && (
                                    <>

                                        <div className="mb-5">
                                            <InputTextBlack
                                                id={'invite_code'}
                                                type={'text'}
                                                errors={errors.invite_code}
                                                input={{ ...register("invite_code", { required: false }) }}
                                                placeholder={'Inserisci codice invito'}
                                                label={'Codice invito (opzionale)'}
                                            />
                                        </div>

                                        <InputPrimarySubmit
                                            isFullWith={true}
                                            isLoading={isLoading}
                                            label={'Vai al gioco!'}
                                        />

                                    </>
                                )
                            }








                        </form>
                    </div>
                </div>

            </GameSlideAlert>
        </>
    );
};

export const InputPrimarySubmit = ({
    isLoading,
    label,
    isFullWith,
    onClick,
    disabled = false,
}) => {
    const { t } = useTranslation();

    return isLoading ? (
        <button
            className={`${isFullWith ? "w-full" : ""
                } btn mt-10  bg-red-600 hover:bg-red-700 text-white  font-bold disabled:border-gray-200 disabled:bg-gray-100 disabled:text-gray-400 disabled:cursor-not-allowed shadow-none`}
            disabled
        >
            <svg
                className="animate-spin w-4 h-4 fill-current shrink-0"
                viewBox="0 0 16 16"
            >
                <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
            </svg>
            <span className="ml-2">
                {t("app.common.loading")}
            </span>
        </button>
    ) : (
        <button
            type="submit"
            onClick={onClick}
            className={`${isFullWith ? "w-full" : ""
                } mt-10 cursor-pointer disabled:opacity-70 disabled:cursor-not-allowed flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-bold  text-white bg-gray-800`}
            disabled={disabled}
        >{label}</button>
    );
};


export default GameOnboarding;