import { useTranslation } from "react-i18next";
import React from "react";

export default function InputTextBlack({
                                    type,
                                    label,
                                    isShowLabel = true,
                                    isRequired,
                                    id,
                                    errors,
                                    warning,
                                    warningRegex,
                                    input,
                                    defaultValue,
                                    className = "",
                                    isDisabled = false,
                                    placeholder = ''
                                  }) {
  const { t } = useTranslation();

  return (
      <div className={className}>
          {
              isShowLabel && (
                  <label
                      htmlFor={id}
                      className="block text-sm font-medium mb-1 text-white"
                  >
                      {label} {isRequired && <span className="text-red-500">*</span>}
                  </label>
              )
          }

        {type === "textarea" ? (
            <textarea
                id={id}
                defaultValue={defaultValue}
                disabled={isDisabled}
                className={`form-input bg-gray-800 w-full disabled:border-gray-200 disabled:bg-gray-100 disabled:text-gray-400 disabled:cursor-not-allowed ${
                    errors
                        ? "border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                        : ""
                    }`}
                placeholder={t("app.common.placeholder", {
                  field: label.toLowerCase(),
                })}
                {...input}
            />
        ) : (
            <input
                type={type}
                id={id}
                defaultValue={defaultValue}
                disabled={isDisabled}
                className={`form-input  w-full  disabled:border-gray-200 disabled:bg-gray-100 disabled:text-gray-400 disabled:cursor-not-allowed ${
                    errors
                        ? "border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                        : ""
                    }`}
                placeholder={placeholder}
                {...input}
            />
        )}
        {errors && errors.type === "required" && (
            <p className="mt-2 text-sm text-red-600">
              {warning ? warning : t("app.warning.description", { field: label })}
            </p>
        )}
        {errors && errors.type === "validate" && (
            <p className="mt-2 text-sm text-red-600">
              {warning ? warning : t("app.warning.description", { field: label })}
            </p>
        )}
        {errors && errors.type === "pattern" && (
            <p className="mt-2 text-sm text-red-600">
              {warningRegex
                  ? warningRegex
                  : t("app.warning.description_regex", { field: label })}
            </p>
        )}
      </div>
  );
}
