/* This example requires Tailwind CSS v2.0+ */
import React, {Fragment, useEffect, useState} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import {BellIcon} from "@heroicons/react/outline/esm";
import Loader from "../loader/loader";
import {useDispatch, useSelector} from "react-redux";
import {getElement, postElement} from "../../api/base-api";
import {getWebPathParam} from "../../utils/converter";
import {NOTIFICATION_READ, NOTIFICATIONS} from "../../routes/endpoints";
import EmptyState from "./empty-state";
import noNotification from "../../resources/images/no-notification.png";
import {useTranslation} from "react-i18next";


export default function Notification() {

    const {notificationCounter} = useSelector((state) => state.config);
    const { loggedCompany } = useSelector((state) => state.user);
    const { t } = useTranslation();

    const [open, setOpen] = useState(false);
    const [list, setList] = useState([]);
    const [isLoading, setLoading] = useState(false);


    const dispatch = useDispatch();



    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        function fetchLists() {
            if(open){
                setLoading(true);
                getElement(getWebPathParam([NOTIFICATIONS]), signal)
                    .then((response) => {
                        setList(response);
                        setLoading(false);
                    })
                    .catch((e) => {
                        setList([]);
                        setLoading(false);
                    });




                postElement(getWebPathParam([NOTIFICATION_READ]))
            }
        }
        fetchLists();
        return () => {
            setList([]);
            controller.abort();
        };
    }, [open, dispatch, loggedCompany]);


    return (
        <>
            <div className="ml-3 relative">

            <div onClick={() => {setOpen(true)}} className="max-w-xs flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                <span className="sr-only">Notifications</span>
                <div className="flex items-center truncate w-8 h-8 flex cursor-pointer items-center justify-center group bg-white hover:bg-gray-300 transition duration-150 rounded-full mr-1">
                        <BellIcon className="h-4 w-4 text-red-600 " aria-hidden="true" />
                        {
                            notificationCounter > 0 && (
                                <span className="absolute top-0 right-0 inline-flex mr-1 items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full">
                                    {notificationCounter >= 9 ? '9+' : notificationCounter}
                                </span>
                            )
                        }
                </div>
            </div>
            </div>

            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="fixed z-10 inset-0 overflow-hidden" onClose={setOpen}>
                    <div className="absolute inset-0 overflow-hidden">
                        <Dialog.Overlay className="fixed inset-0 bg-gray-900 bg-opacity-30 z-5 transition-opacity" />

                        <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <div className="w-screen max-w-md">
                                    <div className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                                        <div className="p-6">
                                            <div className="flex items-start justify-between">
                                                <Dialog.Title className="text-lg font-medium text-gray-900">Notifiche</Dialog.Title>
                                                <div className="ml-3 h-7 flex items-center">
                                                    <button
                                                        type="button"
                                                        className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500"
                                                        onClick={() => setOpen(false)}
                                                    >
                                                        <span className="sr-only">Close panel</span>
                                                        <XIcon className="h-6 w-6" aria-hidden="true" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="border-b border-gray-200" />

                                        {
                                            isLoading || list === undefined ? (
                                                <Loader/>
                                            ) : (
                                                    list.length > 0 ? (
                                                        <>
                                                            <ul  className="flex-1 divide-y divide-gray-200 overflow-y-auto">
                                                                {
                                                                    list.map((data) => (
                                                                        <li key={data.id} className="relative bg-white py-5 px-4 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
                                                                            <div className="flex justify-between space-x-3">
                                                                                <div className="min-w-0 flex-1">
                                                                                    <a href={data.call_to_action} target={'_blank'} rel="noreferrer" onClick={() => setOpen(false)} className="block focus:outline-none">
                                                                                        <span className="absolute inset-0" aria-hidden="true" />
                                                                                        <p className="text-sm font-medium text-gray-900 truncate">{data.title}</p>
                                                                                    </a>
                                                                                </div>
                                                                                <div className="flex-shrink-0 whitespace-nowrap text-sm text-gray-500">
                                                                                    {data.elapsed_time}
                                                                                </div>
                                                                            </div>
                                                                            <div className="mt-1">
                                                                                <p className="line-clamp-2 text-sm text-gray-600">{data.text}</p>
                                                                            </div>
                                                                        </li>

                                                                    ))
                                                                }
                                                            </ul>
                                                        </>
                                                    ) : (
                                                        <EmptyState
                                                            img={noNotification}
                                                            title={t("app.empty_state.notification_title")}
                                                            description={t("app.empty_state.notification_description")}
                                                        />
                                                    )
                                            )
                                        }



                                    </div>
                                </div>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    )
}
