import React from "react";
import {Outlet} from "react-router-dom";
import {Navigation} from "./navigation";
import {Footer} from "./footer";


const GuestLayout = ({Storyblok}) => {

    return (
        <div className="min-h-screen bg-white">
            <Navigation />
            <Outlet />
            <Footer Storyblok={Storyblok} />
        </div>
  );
};


export default GuestLayout;
