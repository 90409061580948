import React, { useEffect, useState } from "react";
import GameSlideFull from "../../../components/game/gamse-slide-full";
import { ChevronLeftIcon, ChevronRightIcon, XIcon } from "@heroicons/react/outline";
// eslint-disable-next-line
import { FOOTBALL_POSITIONS, formatNumber, GAME_LEVELS, UNDER_EDGES } from "../../../constants/config";
import coin from "../../../resources/game/coin.svg";
import { getGameElement } from "../../../api/base-api";
import { getWebPathParam } from "../../../utils/converter";
import { TOP_LEVEL, USER } from "../../endpoints";
import LoaderWrapper from "../../../components/loader/loader-wrapper";
import noImage from "../../../resources/images/user-placeholder.jpg";
import NumberFormat from "react-number-format";
import { Adsense } from "@ctrl/react-adsense";

// eslint-disable-next-line
const GameLevels = ({ isOpen, setIsOpen, curentLevel, levels }) => {
   
    let [current, setCurrent] = useState(0);
    let [isLoading, setLoading] = useState(true);
    let [userLevels, setUserLevels] = useState([]);

    // eslint-disable-next-line
    let previousSlide = () => {
        if (current === 0) setCurrent(GAME_LEVELS.length - 1);
        else setCurrent(current - 1);
    };
    // eslint-disable-next-line
    let nextSlide = () => {
        if (current === GAME_LEVELS.length - 1) setCurrent(0);
        else setCurrent(current + 1);
    };

    useEffect(() => {
        if (isOpen) {
            setCurrent(GAME_LEVELS.find(r => r.level === curentLevel.level_id).level - 1);
        }
        // eslint-disable-next-line
    }, [isOpen]);



    



    useEffect(() => {

        const controller = new AbortController();
        const signal = controller.signal;


        async function fetchUserLevels() {
            if (isOpen) {
                setLoading(true);
                try {
                    const r = await getGameElement(getWebPathParam([USER, TOP_LEVEL]), signal, {
                        limit: 200
                    });
                    setUserLevels(r);
                } catch (e) {
                    setUserLevels([]);
                    if (signal.aborted) {
                        setLoading(true);
                    }
                } finally {
                    if (signal.aborted) {
                        setLoading(true);
                    } else {
                        setLoading(false);
                    }
                }
            }
        }

        fetchUserLevels();

        return () => {
            controller.abort();
        };
        // eslint-disable-next-line
    }, [isOpen, current]);


    return (
        <GameSlideFull
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            overFlow={'overflow-y-scroll'}
            isActiveClose={false}
        >



            <div className={"flex-1 h-0 "}>
               
                <div className="mx-auto">

                    <div className="sticky top-0  z-10 relative ">
                        <div className="top-5 right-2 relative flex items-center justify-end z-50">
                            <button
                                type="button"
                                className="text-white hover:text-white/[0.6]  absolute mr-2"
                                onClick={() => { setIsOpen(false); }}
                            >
                                <span className="sr-only">Close panel</span>
                                <XIcon className="h-5 w-5" aria-hidden="true" />
                            </button>
                        </div>
                      
                        {/*
                          <div className="m-auto">
                            <Carousel slides={GAME_LEVELS} previousSlide={previousSlide} nextSlide={nextSlide} current={current} />
                        </div>
                        <div className="-mt-28 text-center w-full absolute z-[100] ">
                            <div className="text-3xl font-bold tracking-tight text-white">
                                {levels.find(l => l.levid === (current + 1)).level_name}
                            </div>
                            {
                                levels.find(l => l.levid === (current + 1)).level_limit > 0 && (
                                    <div className="text-xs font-semibold text-white/[.8]">
                                        da {formatNumber(levels.find(l => l.levid === (current + 1)).level_limit)}
                                    </div>
                                )
                            }

                        </div>
                        
                        
                        */}
                        
                      
                    </div>


                    <ul className="overflow-y-scroll p-2 ">
                        <div className="my-8 text-center w-full">

                            <div className="text-3xl font-bold tracking-tight text-white">
                                Top 200 Giocatori
                            </div>
                        </div>

                        <LoaderWrapper isLoading={isLoading}>
                            <li className="flex flex-col gap-x-6 my-2 p-3 rounded-xl  backdrop-blur-sm bg-white/10 ">
                                <Adsense
                                    client="ca-pub-7934619462197808"
                                    slot="2308238874"
                                    style={{ display: 'block' }}
                                    layoutKey="-fb+5w+4e-db+86"
                                    adTest="true"
                                    format="fluid"
                                />
                            </li>
                            {userLevels.map((userLevel, index) => (
                                <PlayerCard index={index} userLevel={userLevel} position={index + 1} />
                            ))}

                        
                            
                       </LoaderWrapper>
                    </ul>
                </div>
            </div>


            

          

        </GameSlideFull>
    );
};

export default GameLevels;

// eslint-disable-next-line
const Carousel = ({ slides, current, previousSlide, nextSlide }) => {

    return (
        <div className="overflow-hidden relative  bg-gradient-to-t from-gray-900 to-red-900 rounded-b-[50px] -top-10 shadow-xl">
            <div
                className={`flex transition ease-out duration-40 `}
                style={{
                    transform: `translateX(-${current * 100}%)`,
                }}
            >
                {slides.map((s) => (
                    <>
                        <img key={s.level} className={'w-full mx-auto'} src={s.bg} alt="" />
                    </>
                ))}
            </div>


            <div className="absolute top-0 h-full w-full justify-between items-center flex text-white px-10 text-3xl">
                <button onClick={previousSlide}>
                    <ChevronLeftIcon className="h-5 w-5" />
                </button>
                <button onClick={nextSlide}>
                    <ChevronRightIcon className="h-5 w-5" />
                </button>
            </div>

        </div>
    );
}


export const PlayerCard = ({ userLevel, position, isActivePosition = true, isActiveErned = false, earnedPoint = 0 }) => {



    return (
        <li className="flex flex-col gap-x-6 my-2 p-3 rounded-xl  backdrop-blur-sm bg-white/10 ">



            {
                isActivePosition && (
                    <div className=" flex justify-end  -mt-2">
                        <p className="text-2xl font-semibold text-white">{position}</p>
                    </div>
                )
            }
          
            <div className={isActivePosition ? "flex -mt-6" : "flex" }>
                <div className="flex min-w-0 gap-x-3 items-center">
                    
                    
                    <img alt="" src={userLevel.image ? userLevel.image : noImage} className="h-16 w-16 object-cover flex-none rounded-full bg-gray-50" />
                    
                    <div className="min-w-0 flex-auto">
                        {
                            userLevel.level_id && (
                                <p className="text-xs  text-white capitalize"> {GAME_LEVELS.find(r => r.level === userLevel.level_id).name}</p>
                            )
                        }
                        <p className="text-sm font-semibold  text-white capitalize">{userLevel.name}</p>
                       
                        <p className="flex items-center font-bold justify-start text-xs leading-5 text-white/[.8]">
                            <img src={coin} className="w-4 h-4 mr-1" alt="" />
                            <NumberFormat
                                value={userLevel.user_point.points}
                                displayType={"text"}
                                decimalScale={0}
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                prefix={""}
                            />

                            {
                                isActiveErned && earnedPoint > 0 && (
                                    <>
                                        <img src={coin} className="w-4 h-4 ml-3 mr-1" alt="" />
                                        <NumberFormat
                                            value={earnedPoint}
                                            displayType={"text"}
                                            decimalScale={0}
                                            thousandSeparator={"."}
                                            decimalSeparator={","}
                                            prefix={"+"}
                                        />
                                    </>
                                )
                            }
                          
                        </p>

                        <dl className="mt-3 grid gap-x-8 grid-cols-3 text-left">
                            <div className="overflow-hidden">
                                <dt className="truncate  font-medium text-white uppercase" style={{ fontSize: "0.7rem" }}>
                                    Ruolo
                                </dt>
                                <dd className="text-xs font-semibold tracking-tight text-white">
                                    <div className="flex items-center">
                                        {FOOTBALL_POSITIONS.find(r => r.value === userLevel.player_role)?.label ?? '-'}
                                    </div>
                                </dd>
                            </div>

                            <div className="overflow-hidden">
                                <dt className="truncate font-medium text-white uppercase" style={{ fontSize: "0.7rem" }}>
                                    Leva
                                </dt>
                                <dd className="text-xs font-semibold tracking-tight text-white">
                                    <div className="flex items-center ">
                                        {UNDER_EDGES.find(r => r.value === userLevel.underage)?.label ?? '-'}
                                    </div>
                                </dd>
                            </div>

                            <div className="overflow-hidden">
                                <dt className="truncate font-medium text-white uppercase" style={{ fontSize: "0.7rem" }}>
                                    Squadra
                                </dt>
                                <dd className="text-xs font-semibold tracking-tight text-white">
                                    <div className="flex items-center ">
                                        {userLevel.team !== '' ? userLevel.team : '-'}
                                    </div>
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </div>

        

        </li>
    );
}


