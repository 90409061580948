import React from "react";


const EmptyState = ({img, title, description}) => {
    return (
        <div className="mx-auto max-w-2xl px-6  text-center  lg:px-8">
            <img className="w-96 mx-auto" src={img} alt="" />
            <h1 className="text-2xl font-bold  text-gray-900 sm:text-3xl">{title}</h1>
            <p className="mt-2  text-gray-900/70">{description}</p>
        </div>
    );
};
export default EmptyState;
