import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Link, Navigate, useLocation, useNavigate} from "react-router-dom";
import InputPrimarySubmit from "../../components/form/input-primay-submit";
import InputText from "../../components/form/input-text";
import {INDEX, LOGIN, OAUTH, REGISTER} from "../endpoints";
import {getWebPathParam} from "../../utils/converter";
import {useForm} from "react-hook-form";
import {emailRegex} from "../../constants/regex";
import * as Endpoint from "../endpoints";
import {postElement} from "../../api/base-api";
import {Warning} from "../../components/alert/banner";
import {loginUser} from "../../reducers/userReducer";
import {useDispatch, useSelector} from "react-redux";

const Login = () => {
  const { t } = useTranslation();
  const { register, handleSubmit, formState: { errors } } = useForm();

  const dispatch = useDispatch();
  let navigate = useNavigate();

  const { isAuthenticated } = useSelector((state) => state.user);
  const location = useLocation();



  // USER API
  const [isLoading, setLoading] = useState(false);
  const [exception, setException] = useState(false);


  const onSubmit = (data) => {
    setLoading(true);
    setException(false);
    postElement(getWebPathParam([OAUTH, LOGIN]), data)
        .then(async response => {
          await dispatch(loginUser(response));
          navigate(getWebPathParam([INDEX]));
        }).catch(e => {
          setException(e.message);
          setLoading(false);
    });
  };



  const from = location.state?.from?.pathname || INDEX;

  if(isAuthenticated){
    return (<Navigate to={from} />);
  }

  return(
      <main className="bg-white py-16">

        <div className="max-w-lg mx-auto px-4 py-8 w-3/4">
          <h1 className="text-3xl text-gray-800 font-bold mb-6">{t("app.login.title")}</h1>
          {/* Form */}
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="space-y-4">

              <InputText
                  id={'email'}
                  type={'email'}
                  errors={errors.username}
                  input={{...register("username", { required: true, pattern: emailRegex })}}
                  label={t("app.form.email")}
              />

              <InputText
                  id={'password'}
                  type={'Password'}
                  errors={errors.password}
                  input={{...register("password", { required: true })}}
                  label={t("app.form.password")}
              />

            </div>

            {/* Warning */}
            {exception && (
                <Warning
                    message={exception}
                />
            )}

            <div className="flex items-center justify-between mt-6">
              <div className="mr-1">
                <Link className="text-sm underline hover:no-underline" to={getWebPathParam([Endpoint.RESET_PASSWORD])}>{t("app.forgot_password.question")}</Link>
              </div>
              <InputPrimarySubmit
                  isLoading={isLoading}
                  label={t("app.login.name")}
              />
            </div>
          </form>

          {/* Footer */}
          <div className="pt-5 mt-6 border-t border-gray-200">
            <div className="text-sm">
              {t("app.register.question")} <Link className="font-medium text-red-500 hover:text-red-600" to={getWebPathParam([REGISTER])}>{t("app.register.name")}</Link>
            </div>
          </div>
        </div>

      </main>
  );
};

export default Login;
