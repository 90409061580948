import { useTranslation } from "react-i18next";
import React from "react";
import Select, { components } from "react-select";
import { SelectorIcon } from "@heroicons/react/solid";

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <div className="field__icon cursor-pointer">
        <SelectorIcon className="h-5 w-5 text-gray-400 " aria-hidden="true" />
      </div>
    </components.DropdownIndicator>
  );
};

const indicatorSeparatorStyle = {
  display: "none",
};

const IndicatorSeparator = ({ innerProps }) => {
  return <span style={indicatorSeparatorStyle} {...innerProps} />;
};

const InputSelect = ({
  label,
  isShowLabel  = true,
  isRequired,
  name,
  options = [],
  defaultValue = "",
  value,
  onChange,
  isLoading,
  isDisabled,
  errors,
  warning,
  warningRegex,
  isMulti,
  placeholder,
  isClearable = false,
  isInsideTarget = false
}) => {
  const { t } = useTranslation();

  let components = { IndicatorSeparator, DropdownIndicator };


  return (
    <div>
      {
        isShowLabel && (
              <label
                  htmlFor={name}
                  className="block text-sm font-medium mb-1 text-white"
              >
                {label} {isRequired && <span className="text-red-500">*</span>}
              </label>
          )
      }
      <Select
        classNamePrefix="react-select"
        className={`react-select-container
         ${errors ? "react-select-container-error" : ""}
         ${isInsideTarget ? "react-select-inside-menu" : ""}
         `}
        components={components}
        isLoading={isLoading}
        isMulti={isMulti}
        isDisabled={isDisabled}
        onChange={(selectedOption) => {
          onChange(selectedOption);
        }}
        isClearable={isClearable}
        menuPortalTarget={isInsideTarget ? false: document.body}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        name={name}
        options={options}
        placeholder={placeholder}
        defaultValue={defaultValue}
        value={value}
      />
      {errors && errors.type === "required" && (
        <p className="mt-2 text-sm text-red-600">
          {warning ? warning : t("app.warning.description", { field: label })}
        </p>
      )}
      {errors && errors.type === "validate" && (
        <p className="mt-2 text-sm text-red-600">
          {warning ? warning : t("app.warning.description", { field: label })}
        </p>
      )}
      {errors && errors.type === "pattern" && (
        <p className="mt-2 text-sm text-red-600">
          {warningRegex
            ? warningRegex
            : t("app.warning.description_regex", { field: label })}
        </p>
      )}
    </div>
  );
};

InputSelect.defaultProps = {
  isMulti: false,
};

export default InputSelect;
