import React, {useState} from "react";
import {Link, Navigate} from "react-router-dom";
import { useForm } from "react-hook-form";
import InputText from "../../components/form/input-text";
import InputCheckbox from "../../components/form/input-checkbox";
import InputPrimarySubmit from "../../components/form/input-primay-submit";
import {emailRegex, passwordRegex} from "../../constants/regex";
import {useTranslation} from "react-i18next";
import {
    INDEX,
    LOGIN,
    OAUTH,
    REGISTER,
    RESEND_MAIL,
    VERIFY
} from "../endpoints";
import {getWebPathParam} from "../../utils/converter";
import {postElement, putElement} from "../../api/base-api";
import {SuccessBoxBtn} from "../../components/alert/success-box";
import {Warning} from "../../components/alert/banner";
import {useSelector} from "react-redux";

const Register = () => {
  const { t } = useTranslation();
  const { register, handleSubmit, formState: { errors } } = useForm();


  // USER API
  const [isLoading, setLoading] = useState(false);
  const [exception, setException] = useState(false);
  const [detail, setDetail] = useState({});


  const [isNeedVerification, setNeedVerification] = useState(false);
  const [isResendMailLoading, setResendMailLoading] = useState(false);
  const [resendMailException, setResendMailException] = useState(false);


    const { isAuthenticated } = useSelector((state) => state.user);
    if(isAuthenticated){
        return (<Navigate to={INDEX} />);
    }

  const onSubmit = (data) => {
    setLoading(true);
    setException(false);
    postElement(getWebPathParam([OAUTH, REGISTER]), data)
        .then(response => {
            setDetail(response);
            setNeedVerification(true);
            setLoading(false);
        }).catch(e => {
          setException(e.message);
          setLoading(false);
    });
  };

    const resendMail = async (id) => {
        setResendMailLoading(true);
        putElement(getWebPathParam([VERIFY, RESEND_MAIL, id]))
            .then((response) => {
                setResendMailLoading(false);
            })
        .catch((e) => {
            setResendMailException(e.message);
            setResendMailLoading(false);
        });
    };


  return (
      <main className="bg-white py-16">

        <div className="max-w-lg mx-auto px-4 py-8 w-3/4">


          {
            isNeedVerification ? (
                <SuccessBoxBtn
                    title={t('app.register.success_verify_title', {firstname: detail.firstname, lastname:detail.lastname })}
                    message={t('app.register.success_verify_message')}
                    label={t('app.register.btn_verify_name')}
                    isLoading={isResendMailLoading}
                    loadingLabel={t('app.common.loading')}
                    exception={resendMailException}
                    onClick={() => {resendMail(detail.id)}}
                />
            ) : (
                <>
                  <h1 className="text-3xl text-gray-800 font-bold mb-6">{t("app.register.title")}</h1>
                  {/* Form */}
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="space-y-4">

                      <div className="mt-6 grid md:grid-cols-2 lg:grid-cols-2 gap-y-1 gap-x-2 sm:grid-cols-1">
                        <InputText
                            id={'firstname'}
                            type={'text'}
                            errors={errors.firstname}
                            input={{...register("firstname", { required: true })}}
                            label={t("app.form.firstname")}
                        />

                        <InputText
                            id={'lastname'}
                            type={'text'}
                            errors={errors.lastname}
                            input={{...register("lastname", { required: true })}}
                            label={t("app.form.lastname")}
                        />
                      </div>

                      <InputText
                          id={'email'}
                          type={'email'}
                          errors={errors.email}
                          input={{...register("email", { required: true, pattern: emailRegex })}}
                          label={t("app.form.email")}
                      />

                      <InputText
                          id={'password'}
                          type={'Password'}
                          errors={errors.password}
                          input={{...register("password", { required: true, pattern: passwordRegex })}}
                          label={t("app.form.password")}
                          warningRegex={t("app.warning.password_regex")}
                      />

                      <InputCheckbox
                          label={t("app.register.privacy_checkbox.title")}
                          description={(<a href="https://www.iubenda.com/privacy-policy/32702144/legal" target="_blank" rel="noreferrer" className="underline">{t("app.register.privacy_checkbox.description")}</a> )}
                          id={'privacy'}
                          errors={errors.privacy}
                          input={{...register("privacy", { required: true })}}
                      />

                      <InputCheckbox
                          label={t("app.register.terms_checkbox.title")}
                          description={(<a href="https://www.iubenda.com/termini-e-condizioni/32702144" target="_blank" rel="noreferrer" className="underline">{t("app.register.terms_checkbox.description")}</a> )}
                          id={'terms'}
                          errors={errors.terms}
                          input={{...register("terms", { required: true })}}
                      />

                      <p className="text-xs mt-2">{t("app.register.indication")}</p>
                    </div>

                      {/* Warning */}
                      {exception && (
                          <Warning
                              message={exception}
                          />
                      )}

                    <div className="flex items-center justify-between mt-6">
                      <InputPrimarySubmit
                          isLoading={isLoading}
                          label={t("app.register.name")}
                          isFullWith={true}
                      />
                    </div>


                  </form>
                  {/* Footer */}
                  <div className="pt-5 mt-6 border-t border-gray-200">
                    <div className="text-sm">
                      {t("app.login.question")} <Link className="font-medium text-red-500 hover:text-indigo-600" to={getWebPathParam([LOGIN])}>{t("app.login.name")}</Link>
                    </div>
                  </div>

                </>
            )
          }



        </div>

      </main>
  );
};

export default Register;
