import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getUserDetails } from "../api/user";

import {
  setToken,
  isAuthenticated,
  getToken,
  getGameToken,
  setGameToken,
} from "../utils/tokenUtil";

const initialState = {
  isAuthenticated: isAuthenticated(),
  token: getToken(),
  game_token: getGameToken(),
  detail: null,
};


/***************
 * ASYNC THUNK *
 ***************/

export const getDetails = createAsyncThunk("user/details", async () => {
  try {
    const response = isAuthenticated() ? await getUserDetails() : null;
    if (response.game_token) {
      await setGameToken(response.game_token);
    }

    return {
      detail: response,
      game_token: await getGameToken(),
    };
  } catch (e) {
    throw e;
  }
});


export const refreshDetails = createAsyncThunk("user/refresh_details", async () => {
  try {
    const response = await getUserDetails();
    if (response.game_token) {
      await setGameToken(response.game_token);
    }

    return {
      detail: response,
      game_token: await getGameToken(),
    };
  } catch (e) {
    throw e;
  }
});


export const setDetails = createAsyncThunk("user/set_details", async (response) => {
  try {

    if(response.token){
      await setToken(response.token);
    }

    if (response.game_token) {
      await setGameToken(response.game_token);
    }

    return {
      detail: response,
      isAuthenticated: true,
      token: await getToken(),
      game_token: await getGameToken(),
    };
  } catch (e) {
    throw e;
  }
});

export const onboardGameToken = createAsyncThunk("user/game_token", async (response) => {
  try {

    if (response) {
      await setGameToken(response);
    }
    return {
      game_token: await getGameToken(),
    };
  } catch (e) {
    throw e;
  }
});


export const login = createAsyncThunk("oauth/login", async (response) => {
  try {
    await setToken(response.token);
    return {
      isAuthenticated: true,
      token: await getToken(),
      detail: null,
    };
  } catch (e) {
    throw e;
  }
});

/*******************
 * EXPOSED          *
 *******************/

export const loadUserDataIfMissing = () => (dispatch, getState) => {
  const { detail, isAuthenticated } = getState().user;
  if (detail === null && isAuthenticated) {
    dispatch(getDetails());
  }
};


export const setUserData = (data) => (dispatch, getState) => {
  dispatch(setDetails(data));

};

export const loginUser = (data) => (dispatch, getState) => {
  dispatch(login(data));

};

export const refreshDetail = () => (dispatch, getState) => {
  dispatch(refreshDetails());
};

/*******************
 * REDUCERS EVENTS *
 *******************/

export const userReducer = createSlice({
  name: "user",
  initialState,
  extraReducers: (builder) => {
    builder
        .addCase(getDetails.pending, (state) => {
          state.isDetailLoading = true;
        })
        .addCase(getDetails.fulfilled, (state, action) => {
          state.detail = action.payload.detail;

          if (action.payload.game_token){
            state.game_token = action.payload.game_token;
          }
          state.isDetailLoading = false;
        })
        .addCase(getDetails.rejected, (state, action) => {
          state.isDetailLoading = false;
          state.loginException = action.error.message;
        })
    builder
        .addCase(refreshDetails.pending, (state) => {
          state.isDetailLoading = false;
        })
        .addCase(refreshDetails.fulfilled, (state, action) => {
          state.detail = action.payload.detail;
          state.isDetailLoading = false;
        })
        .addCase(refreshDetails.rejected, (state, action) => {
          state.isDetailLoading = false;
          state.loginException = action.error.message;
        })
    builder
        .addCase(setDetails.pending, (state) => {
          state.isSetDetailLoading = true;
        })
        .addCase(setDetails.fulfilled, (state, action) => {
          for (const k in action.payload) {
            state[k] = action.payload[k];
          }
          state.isSetDetailLoading = false;
        })
        .addCase(setDetails.rejected, (state, action) => {
          state.isSetDetailLoading = false;
          state.loginException = action.error.message;
        })
    builder
        .addCase(login.pending, (state) => {
          state.isSetDetailLoading = true;
        })
        .addCase(login.fulfilled, (state, action) => {
          for (const k in action.payload) {
            state[k] = action.payload[k];
          }
          state.isSetDetailLoading = false;
        })
        .addCase(login.rejected, (state, action) => {
          state.isSetDetailLoading = false;
          state.loginException = action.error.message;
        })
    builder
      .addCase(onboardGameToken.pending, (state) => {
        state.isLoadingGameToken = true;
      })
      .addCase(onboardGameToken.fulfilled, (state, action) => {
        for (const k in action.payload) {
          state[k] = action.payload[k];
        }
        state.isLoadingGameToken = false;
      })
      .addCase(onboardGameToken.rejected, (state, action) => {
        state.isLoadingGameToken = false;
      })    
    ;
  },
});

export default userReducer.reducer;
