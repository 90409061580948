import firebase from 'firebase/app';
import 'firebase/messaging';
import {putUser} from "../api/user";
import {getData, setData} from "../utils/cacheUtil";

const firebaseConfig = {
    apiKey: "AIzaSyATe_tPkTbObGcDxaNqKaVC3I8PUUd86e0",
    authDomain: "minigoal-98bcb.firebaseapp.com",
    projectId: "minigoal-98bcb",
    storageBucket: "minigoal-98bcb.appspot.com",
    messagingSenderId: "701727361139",
    appId: "1:701727361139:web:d39153b18c7a1ac5668206",
    measurementId: "G-HPPN5F4TC8"
};

const publicKey = 'BFPdzI0tE7zy8deiq91Pl8zsk9hjkmvkrjuvQAUUQ2G49996KdJk6MJL9c7QyeGa14DYgqDAuizPN_KKxlYHyP0';
const audio = new Audio('https://freesound.org/data/previews/91/91926_7037-lq.mp3');

let messaging = null;
if (firebase.messaging.isSupported()) {
    if (!firebase.apps.length) {
        firebase.initializeApp(firebaseConfig);
    }else {
        firebase.app(); // if already initialized, use that one
    }
    messaging = firebase.messaging()
} else {
    console.log('no-support :(')
}

export const isActiveFirebase  = () => messaging !== null;

export const requestForToken = (detail) => {
    return messaging?.getToken({ vapidKey: publicKey })
        .then((currentToken) => {
            if (currentToken && (!getData('is_web_token_granted') || getData('is_web_token_granted') !== currentToken)) {
                if(detail){
                    putUser({user_id: detail.id, data: {id: detail.id, push_token_web: currentToken}});
                    setData('is_web_token_granted', currentToken);
                }
            }
        })
        .catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
        });
};


export const onMessageListener = () =>
    new Promise((resolve) => {
        messaging.onMessage( (payload) => {
           // console.log("payload", payload);
            audio.play();
            resolve(payload);
        });
    });
