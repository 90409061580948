import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import {getElement} from "../../api/base-api";
import {getWebPathParam} from "../../utils/converter";
import {CHECK, DASHBOARD, PAYMENT} from "../endpoints";
import {Link} from "react-router-dom";
import LoaderWrapper from "../../components/loader/loader-wrapper";
import {refreshDetail} from "../../reducers/userReducer";
import {useDispatch} from "react-redux";
import EmptyState from "../../components/layout/empty-state";
import noTransactions from "../../resources/images/no-transactions.png";
import noData from "../../resources/images/no-data.png";

const STATUS_OK = 'OK';
const STATUS_KO = 'KO';


const CheckPayment = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [isLoading, setLoading] = useState(false);
    const [status, setStatus] = useState(STATUS_KO);


    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        function checkCode() {


            const session = new URLSearchParams(window.location.search).get(
                "session_id"
            );

            const session_paypal = new URLSearchParams(window.location.search).get(
                "paymentId"
            );
            const session_paypal_id = new URLSearchParams(window.location.search).get(
                "PayerID"
            );

            if(session || session_paypal){

                let params = {};
                if(session){
                     params = {session_token: session};
                }

                if(session_paypal && session_paypal_id){
                     params = {session_token: session_paypal, payer_id: session_paypal_id};
                }


                setLoading(true);
                getElement(getWebPathParam([PAYMENT, CHECK]), signal, params)
                    .then(async (response) => {
                        await dispatch(refreshDetail(response));
                        setStatus(response.status);
                        setLoading(false);
                    })
                    .catch((e) => {
                        setStatus(STATUS_KO);
                        setLoading(false);
                    });
            }
        }

         checkCode();

        return () => {
            controller.abort();
        };
        // eslint-disable-next-line
    }, []);

    return (
        <div className="py-12">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                <LoaderWrapper isLoading={isLoading}>
                    <div className="mx-auto ">

                        {
                            status === STATUS_OK && (
                                <>
                                    <EmptyState
                                        img={noTransactions}
                                        title={t("app.payment.confirm_title")}
                                        description={t("app.payment.confirm_description")}
                                    />
                                </>
                            )
                        }

                        {
                            status === STATUS_KO && (
                                <>
                                    <EmptyState
                                        img={noData}
                                        title={t("app.payment.error_title")}
                                        description={t("app.payment.error_description")}
                                    />
                                </>
                            )
                        }

                        <div className="mt-10 flex items-center justify-center gap-x-6">
                            <Link className="btn mt-2 bg-red-600 hover:bg-red-700 text-white" to={getWebPathParam([DASHBOARD])}>{t('app.common.go_to', {label: ' Dashboard ->'})}</Link>
                        </div>

                    </div>
                </LoaderWrapper>
            </div>
        </div>
    );
};

export default CheckPayment;
