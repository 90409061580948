import React, {useEffect, useRef, useState} from "react";
import { useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import InputText from "../../components/form/input-text";
import {passwordRegex} from "../../constants/regex";
import InputPrimarySubmit from "../../components/form/input-primay-submit";
import {useForm} from "react-hook-form";
import {getElement, putElement} from "../../api/base-api";
import {getWebPathParam} from "../../utils/converter";
import {CHECK, LOGIN, RESET_PASSWORD} from "../endpoints";
import Loader from "../../components/loader/loader";
import {FailedBoxLink} from "../../components/alert/failed-box";
import {SuccessBoxLink} from "../../components/alert/success-box";
import {Warning} from "../../components/alert/banner";

const ResetPassword = () => {

  const { register, handleSubmit,watch, formState: { errors } } = useForm();

  const { confirmation_code } = useParams();
  const { t } = useTranslation();

  let currentPassword = useRef({});
  currentPassword = watch("password", "");


  // Check email confirmation
  const [isCheckConfirmed, setCheckConfirmed] = useState(true);
  const [isCheckResetLoading, setCheckResetLoading] = useState(true);
  const [checkResetException, setCheckResetException] = useState(false);


  // Confirm email
  const [isResetConfirmed, setResetConfirmed] = useState(false);
  const [resetException, setResetException] = useState(false);
  const [isResetPasswordLoading, setResetPasswordLoading] = useState(false);


  const onSubmit = async (data) => {
    setResetPasswordLoading(true);
    await putElement(getWebPathParam([RESET_PASSWORD, confirmation_code]), data).then(async (response) => {
      setResetPasswordLoading(false);
      setResetConfirmed(response.status === "ok");
    }).catch((e) => {
      setResetException(e.message);
      setResetPasswordLoading(false);
    });
  };



  useEffect(() => {
    function fetchData() {
      setCheckResetLoading(true);
      getElement(getWebPathParam([RESET_PASSWORD, confirmation_code, CHECK])).then((response) => {
        setCheckConfirmed(true);
        setCheckResetLoading(false);
      }).catch((e) => {
        setCheckConfirmed(false);
        setCheckResetException(e.message);
        setCheckResetLoading(false);
      });
    }

    fetchData();
    // eslint-disable-next-line
  }, [confirmation_code]);



  return(
      <main className="bg-white py-16">

        <div className="max-w-lg mx-auto px-4 py-8 w-3/4">
          {
            isCheckResetLoading ? (
                <Loader />
            ) : (
                isCheckConfirmed ? (
                    !isResetConfirmed ? (
                        <>
                          <h1 className="text-3xl text-gray-800 font-bold mb-6">{t("app.reset_password.title")}</h1>

                          {/* Form */}
                          <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="space-y-4">
                              <InputText
                                  id={'password'}
                                  type={'password'}
                                  label={t("app.form.password")}
                                  errors={errors.password}
                                  input={{...register("password", { required: true, pattern: passwordRegex })}}
                                  warningRegex={t("app.warning.password_regex")}
                              />

                              <InputText
                                  id={'password_repeat'}
                                  type={'password'}
                                  label={t('app.form.password_repeat')}
                                  errors={errors.passwordRepeat}
                                  input={{...register("passwordRepeat", { validate: value => value === currentPassword || "The passwords do not match" })}}
                                  warning={t('app.warning.password_match')}
                              />

                            </div>

                            {/* Warning */}
                            {resetException && (
                                <Warning
                                    message={resetException}
                                />
                            )}

                            <div className="flex items-center justify-between mt-6">
                              <InputPrimarySubmit
                                  isLoading={isResetPasswordLoading}
                                  label={t('app.reset_password.confirm_name')}
                                  isFullWith={true}
                              />
                            </div>
                          </form>
                        </>
                    ) : (
                        <SuccessBoxLink
                            title={t('app.reset_password.success_title')}
                            message={t('app.reset_password.success_message')}
                            link={getWebPathParam([LOGIN])}
                            linkTitle={t('app.common.go_to', {label: ' Login ->'})}
                        />
                    )
                ) : (
                    <FailedBoxLink
                        title={t('app.message.generic_not_found')}
                        message={checkResetException}
                        link={getWebPathParam([LOGIN])}
                        linkTitle={t('app.common.go_to', {label: ' Login ->'})}
                    />
                )
            )
          }

        </div>

      </main>
  );
};

export default ResetPassword;
