import React, { useEffect, useState } from "react";
import GameSlideFull from "../../../components/game/gamse-slide-full";
import { getGameElement, postGameElement } from "../../../api/base-api";
import { getWebPathParam } from "../../../utils/converter";
import { USER_TASK, SOCIAL, TASKS } from "../../endpoints";
import LoaderWrapper from "../../../components/loader/loader-wrapper";
import coinBg from "../../../resources/game/coin-bg.png";
import coin from "../../../resources/game/coin.svg";
import checkImage from "../../../resources/game/check.png";
import NumberFormat from "react-number-format";
import instagramImage from "../../../resources/game/instagram.png";
import facebookImage from "../../../resources/game/facebook.png";
import tiktokImage from "../../../resources/game/tiktok.png";
import videoImage from "../../../resources/game/youtube.png";
import GameSlideAlert from "../../../components/game/gamse-slide-alert";
import { ExclamationIcon } from "@heroicons/react/outline/esm";

const GameEarning = ({ isOpen, setIsOpen, onNeedRefresh }) => {
   
    let [isLoading, setLoading] = useState(true);

    let [oneTimeTasks, setOneTimeTasks] = useState([]);
    let [dailyTasks, setDailyTasks] = useState([]);
    let [isOpenAlert, setOpenAlert] = useState(false);
    let [taskDetail, setTaskDetail] = useState(false);

    let [isOpenErningCofirm, setOpenErningCofirm] = useState(false);
    let [erningPoint, setErningPoint] = useState(0);
    let [taskStatusWaiting, setTaskStatusWaiting] = useState(false);
    let [isLoadingOneTime, setLoadingOneTime] = useState(false);
    let [refreshData, setRefreshData] = useState(null);



    async function fetchTasks() {
        if (isOpen) {
            if (refreshData === null) {
                setLoading(true);
            }
            try {
                const r = await getGameElement(getWebPathParam([TASKS]));
                setOneTimeTasks(r.social);
                setDailyTasks(r.video);
                setRefreshData(false);
            } catch (e) {
                setLoading(true);
            } finally {
                setLoading(false);
                setRefreshData(false);
            }
        }
    }

    useEffect(() => {
        fetchTasks();
        // eslint-disable-next-line
    }, [isOpen]);


    function createOneTimeTask(data) {
        setLoadingOneTime(true);
        setTaskStatusWaiting(false);
        postGameElement(getWebPathParam([USER_TASK, SOCIAL]), data)
            .then(async (response) => {

                if (response.status === 'waiting'){
                    setTaskStatusWaiting(true);
                } else if (response.status === 'confirmed' && response.points > 0) {
                    setErningPoint(response.points);
                    setOpenErningCofirm(true)
                    setOpenAlert(false);
                }else{
                    setOpenAlert(false);
                }


                setLoadingOneTime(false);
            })
            .catch((e) => {
                setLoadingOneTime(false);
            });

    }


    return (
        <>
            <GameSlideFull
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                overFlow={'overflow-y-scroll'}
            >

                <div className="overflow-y-scroll">
                    <div className="relative">
                        <img className={'mt-3  mx-auto'} style={{ width: '300px' }} src={coinBg} alt="" />
                    </div>
                    <div className="-mt-4 text-center w-full">
                        <div className="text-3xl font-bold tracking-tight text-white">
                            Guadagna piu coin
                        </div>
                    </div>



                    <div className="p-4 mt-10">


                        <ul className="overflow-y-scroll mb-6">
                            <div className="text-left w-full mb-3">
                                <div className="text-md font-bold tracking-tight text-white">
                                    Attività Giornaliera
                                </div>
                            </div>

                            <LoaderWrapper isLoading={isLoading}>
                                {dailyTasks.map((task, index) => (
                                    <DailyTasksCard key={index} task={task} onOpenAlert={(r) => {
                                        setTaskDetail(r);
                                        setOpenAlert(true);
                                    }} />
                                ))}
                            </LoaderWrapper>
                        </ul>

                        <ul className="overflow-y-scroll">
                            <div className="text-left w-full mb-3">
                                <div className="text-md font-bold tracking-tight text-white">
                                    Attività
                                </div>
                            </div>

                            <LoaderWrapper isLoading={isLoading}>
                                {oneTimeTasks.map((task, index) => (
                                    <OneTimeTaskCard key={index} task={task} 
                                    onOpenAlert={(r) => {
                                        setTaskDetail(r);
                                        setOpenAlert(true);
                                    }} />
                                ))}
                            </LoaderWrapper>
                        </ul>

                    </div>
                </div>

            </GameSlideFull>


            <GameSlideAlert
                isOpen={isOpenAlert}
                setIsOpen={(r)=> {
                    setTaskStatusWaiting(false);
                    setOpenAlert(r);
                }}



            >

                <div className="flex flex-col  justify-center grid justify-items-center">
                    <div className="mb-6"> <img alt="" src={taskDetail?.image ?? '#'} className="h-24 w-24 object-cover  " /></div>
                    <p className="text-xl font-semibold leading-6 text-white mb-3">{taskDetail?.title ?? ''}</p>

                    <p className="flex items-center font-bold justify-start text-2xl  leading-5 text-white mb-6">
                        <img src={coin} className="w-8 h-8 mr-1" alt="" />
                        +<NumberFormat
                            value={taskDetail?.task_point ?? ''}
                            displayType={"text"}
                            decimalScale={0}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            prefix={""}
                        />
                    </p>

                    {
                        taskStatusWaiting && (
                            <div className="rounded-2xl bg-yellow-600 p-4 mb-6">
                                <div className="flex items-center">
                                    <div className="flex-shrink-0">
                                        <ExclamationIcon aria-hidden="true" className="h-5 w-5 text-yellow-50" />
                                    </div>
                                    <div className="ml-3">
                                        <h3 className="text-sm font-medium text-yellow-50">Bisogna attendere 1 ora per <br />riscattere il premio</h3>
                                    </div>
                                </div>
                            </div>
                        )
                    }


                    {
                        taskDetail.type !== 'video' && (
                            <div className="flex items-center">

                                {
                                    taskDetail.hasLink && (
                                        <div className="ml-3">
                                            <GameBtn
                                                isLoading={isLoadingOneTime}
                                                label={!taskDetail.status ? 'Riscatta & Visita': 'Visita'}
                                                onClick={() => {

                                                    if (taskDetail.status){
                                                        try {
                                                            window.location.href = taskDetail?.link ?? '';
                                                        } catch (error) {
                                                            //alert(`Error: ${error.message}`);
                                                        }
                                                    }else{
                                                        try {
                                                            window.location.href = taskDetail?.link ?? '';
                                                            createOneTimeTask({ task_id: taskDetail.task_id })
                                                        } catch (error) {
                                                            //alert(`Error: ${error.message}`);
                                                        }
                                                    }


                                                    

                                                }}
                                            />
                                        </div>
                                    )
                                }

                            </div>
                        )
                    }
                    

                   
                </div>
             
            </GameSlideAlert>
            
            <GameEarningAlert isOpenAlert={isOpenErningCofirm} setOpenAlert={setOpenErningCofirm} points={erningPoint} onNeedRefresh={() => {
                fetchTasks();
                onNeedRefresh()
            }} />
        
        </>
    );
};


const OneTimeTaskCard = ({ task, onOpenAlert }) => {

    let image = '#';
    let title = '';
    let androidLink = task.property.android_url;
    let iosLink = task.property.ios_url

    let link = '';
    if (task.code === 'instagram') {
        image = instagramImage;
        title = "Seguici su Instagram";
    }
    if (task.code === 'facebook') {
        image = facebookImage;
        title = "Seguici su Facebook";

    }
    if (task.code === 'tiktok') {
        image = tiktokImage;
        title = "Seguici su Tiktok";
    }

    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // iOS detection
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        link = iosLink;
    }

    // Android detection
    if (/android/i.test(userAgent)) {
        link = androidLink;
    }


    return (
        <>
            <li className="flex justify-between gap-x-6 my-2 p-2 rounded-xl  backdrop-blur-sm bg-white/10 " onClick={() => {
                let details = {
                    type: 'social',
                    task_id: task.task_id,
                    image: image,
                    hasLink: true,
                    link: link,
                    title: title,
                    task_point: task.points,
                    status: task.status
                }
                onOpenAlert(details)
            }}>
                <div className="flex min-w-0 gap-x-4">
                    <img alt="" src={image} className="h-12 w-12 object-cover flex-none " />
                    <div className="min-w-0 flex-auto">
                        <p className="text-sm font-semibold leading-6 text-white/[.8]">{title}</p>
                        <p className="flex items-center font-bold justify-start text-xs leading-5 text-white">
                            <img src={coin} className="w-6 h-6 mr-1" alt="" />
                            +<NumberFormat
                                value={task.points}
                                displayType={"text"}
                                decimalScale={0}
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                prefix={""}
                            />
                        </p>
                    </div>
                </div>
                <div className=" shrink-0 flex flex-row items-center">
                    {
                        task.status && (
                            <p className="text-2xl font-semibold text-white">
                                <img alt="" src={checkImage} className="h-10 w-10 object-cover flex-none " />
                            </p>
                        )
                    }
                </div>
            </li>
        </>
    )
}


const DailyTasksCard = ({ task, onOpenAlert }) => {

    let image = '#';
    let title = '';
    if (task.code === 'video') {
        image = videoImage;
        title = "Carica un video sull'app"; 
    }

    return(
       <>
            <li className="flex justify-between gap-x-6 my-2 p-2 rounded-xl  backdrop-blur-sm bg-white/10 " onClick={() => {
                let details = {
                    type: 'video',
                    task_id: task.task_id,
                    image: image,
                    hasLink: false,
                    title: title,
                    task_point: task.points,
                    status: task.status
                }
                onOpenAlert(details)
            }}>
                <div className="flex min-w-0 gap-x-4">
                    <img alt="" src={image} className="h-12 w-12 object-cover flex-none " />
                    <div className="min-w-0 flex-auto">
                        <p className="text-sm font-semibold leading-6 text-white/[.8]">{title}</p>
                        <p className="flex items-center font-bold justify-start text-xs leading-5 text-white">
                            <img src={coin} className="w-6 h-6 mr-1" alt="" />
                            +<NumberFormat
                                value={task.points}
                                displayType={"text"}
                                decimalScale={0}
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                prefix={""}
                            />
                        </p>
                    </div>
                </div>
                <div className=" shrink-0 flex flex-row items-center">
                    {
                        task.status && (
                            <p className="text-2xl font-semibold text-white">
                                <img alt="" src={checkImage} className="h-10 w-10 object-cover flex-none " />
                            </p>
                        )
                    }
                </div>
            </li>
        
       </>
    )
}

export const GameBtn = ({ label, onClick, isFullWith = false, disabled = false }) => {
    

    return(
        <button
            type="submit"
            onClick={onClick}
            className={`${isFullWith ? "w-full" : ""
                }  cursor-pointer disabled:opacity-70 disabled:cursor-not-allowed flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-bold  text-white bg-red-600 hover:bg-red-700 `}
            disabled={disabled}
        >{label}</button>
    )
}





export const GameEarningAlert = ({ isOpenAlert, setOpenAlert, points, onNeedRefresh }) => {


    return(
        <GameSlideAlert
            isOpen={isOpenAlert}
            setIsOpen={setOpenAlert}
        >

            <div className="flex flex-col  justify-center grid justify-items-center">

                <div className="mb-6"> <img alt="" src={coin} className="h-24 w-24 object-cover  " /></div>
                <p className="text-xl font-semibold text-center leading-6 text-white mb-6">Congratulazioni!<br />Hai sbloccato{' '}

                    <span className="font-bold text-xl  font-bold  text-white ">
                    +<NumberFormat
                        value={points}
                        displayType={"text"}
                        decimalScale={0}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        prefix={""}
                    />
                    </span> coin
                </p>
                <div className="flex items-center ">
                    <GameBtn
                        label={'Chiudi'}
                        onClick={() => {
                            if (onNeedRefresh){
                                onNeedRefresh(true);
                            }
                            setOpenAlert(false)
                        }}
                    />
                </div>


            </div>

        </GameSlideAlert>
    )
}





export default GameEarning;
