import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import {getElement} from "../../api/base-api";
import {getWebPathParam} from "../../utils/converter";
import {MAGAZINES} from "../endpoints";
import LoaderWrapper from "../../components/loader/loader-wrapper";
import {useParams} from "react-router-dom";
import MagazineCard from "../../components/pdf/magazine-card";

const Season = () => {
    // eslint-disable-next-line
    const { t } = useTranslation();
    let {id}  = useParams();


    const [magazines, setMagazines] = useState([]);
    const [isLoading, setLoading] = useState(false);
    // eslint-disable-next-line
    const [exception, setException] = useState(false);
    const [seasonDetail, setSeasonDetail] = useState(false);
    const [refreshData, setRefreshData] = useState(null);



    function fetchMagazines(signal = null) {

        if(refreshData === null){
            setLoading(true);
        }
        getElement(getWebPathParam([MAGAZINES]), signal, {season: id})
            .then(async (response) => {
                setMagazines(response.magazines);
                setSeasonDetail(response.season);
                setLoading(false);
                setRefreshData(false);
            })
            .catch((e) => {
                setException(e.message);
                setLoading(false);
            });
    }

    useEffect(() => {

        const controller = new AbortController();
        const signal = controller.signal;

        fetchMagazines(signal);

        return () => {
            controller.abort();
        };
        // eslint-disable-next-line
    }, [id]);


    return (
        <div className="py-12">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                <div className="border-b border-gray-200 pb-5">


                    <LoaderWrapper isLoading={isLoading}>


                        <h3 className="text-base font-semibold leading-6 text-gray-900">
                            {seasonDetail.title}
                        </h3>
                        <p className="mt-2 max-w-4xl text-sm text-gray-500">
                            {seasonDetail?.description ?? ''}
                        </p>

                        <ul
                            className="mx-auto mt-20 grid max-w-2xl grid-cols-2 gap-y-20 gap-x-6 sm:grid-cols-3 lg:mx-0 md:max-w-none lg:grid-cols-4 sm:gap-y-20 sm:gap-x-8 md:gap-x-10 lg:gap-x-12"
                        >
                            {magazines.map((item) => (
                                <li key={item.id}>
                                    <MagazineCard
                                        image={item?.picture?.reference ?? false}
                                        title={item.title}
                                        description={item.description}
                                        magazineDetail={item}
                                        onRefreshData={(r) => { setRefreshData(r); fetchMagazines();}}
                                    />
                                </li>
                            ))}
                        </ul>
                    </LoaderWrapper>

                </div>
            </div>
        </div>
    );
};

export default Season;
