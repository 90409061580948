import {useEffect, useState} from "react";

export const Warning  = ({message}) =>(
    <div className="mt-5">
        <div className={`bg-red-50 border-l-4 border-red-400 p-4`}>
            <div className="flex">
                <div className="ml-3">
                    <p className={`text-sm text-red-700`}>
                        {message}
                    </p>
                </div>
            </div>
        </div>
    </div>
);

export const Alert = ({message}) => (
    <div className="mt-5">
        <div className={`bg-yellow-50 border-l-4 border-yellow-400 p-4`}>
            <div className="flex">
                <div className="ml-3">
                    <p className={`text-sm text-yellow-700`}>
                        {message}
                    </p>
                </div>
            </div>
        </div>
    </div>
);

export const Success = ({message, hasMargin = true}) =>{
    const [show, setShow] = useState(true);

    useEffect(() => {
        const interval = setInterval(() => {
            setShow(false)
        }, 3000);

        return () => {
            clearInterval(interval);
        };
    });

    return  show ? (
        <div className={hasMargin && 'mt-5 mb-5'}>
            <div className={`bg-green-50 border-l-4 border-green-400 p-4`}>
                <div className="flex">
                    <div className="ml-3">
                        <p className={`text-sm text-green-700`}>
                            {message}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    ) : <></>
};

