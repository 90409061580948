import React from "react";
import * as Endpoint from "./endpoints";
import { Route, Routes } from "react-router";
import {getWebPathParam} from "../utils/converter";
import { useSelector } from "react-redux";
import Loading from "../components/layout/loading";

// layout
import GuestLayout from "../components/layout/guest-layout";
import RequireAuth from "../components/layout/require-auth";
import Register from "./oauth/register";
import Login from "./oauth/login";
import ResetPasswordRequest from "./oauth/reset-password-request";
import ResetPassword from "./oauth/reset-password";
import ConfirmRegistration from "./oauth/confirm-registration";
import Page404 from "./404";
import Dashboard from "./pages/dashboard";
import UserSettings from "./user/user-settings";
import UserProfile from "./user/user-profile";
import Season from "./pages/season";
import MyMagazine from "./pages/my-magazine";
import Archive from "./pages/archive";
import CheckPayment from "./pages/check-payment";
import HowWork from "./pages/how-work";
import UserTransactions from "./user/user-transactions";
import Privacy from "./pages/privacy";
import Terms from "./pages/terms";
import StoryblokClient from "storyblok-js-client";
import Redirect from "./pages/Redirect";
import RequireAuthGame from "../components/layout/require-auth-game";
import StartGame from "./pages/game/start-game";
import GameBridge from "./pages/game/game-bridge";

function Main() {

    const Storyblok = new StoryblokClient({
        accessToken: 'oKR2zaroOBIr04XnMQtYXwtt',
    });
    const { isDetailLoading } = useSelector((state) => state.user);
    if (isDetailLoading) {
        return <Loading />;
    }



  return (
    <Routes>

      {/* GUEST LAYOUT ROUTING */}
      <Route path="/" element={<GuestLayout Storyblok={Storyblok} />}>
          <Route path={getWebPathParam([Endpoint.REGISTER])} element={<Register />} />
          <Route path={getWebPathParam([Endpoint.LOGIN])} element={<Login />} />
          <Route path={getWebPathParam([Endpoint.RESET_PASSWORD])} element={<ResetPasswordRequest />} />
          <Route path={getWebPathParam([Endpoint.RESET_PASSWORD, "/:confirmation_code"])} element={<ResetPassword />} />
          <Route path={getWebPathParam([Endpoint.VERIFY_REGISTRATION, "/:confirmation_code"])} element={<ConfirmRegistration />} />
          <Route path={getWebPathParam([Endpoint.PAGES, Endpoint.PRIVACY])} element={<Privacy />} />
          <Route path={getWebPathParam([Endpoint.PAGES, Endpoint.TERMS])} element={<Terms />} />
          <Route path={getWebPathParam([Endpoint.PAGES, 'test'])} element={<Redirect />} />
      </Route>

      {/* REQUIRE AUTH ROUTING */}
      <Route path="/" element={<RequireAuth />}>
          <Route index path={getWebPathParam([Endpoint.INDEX])} element={<Dashboard />} />
          <Route index path={getWebPathParam([Endpoint.SEASONS, Endpoint.ID])} element={<Season />} />
          <Route index path={getWebPathParam([Endpoint.MY_MAGAZINE])} element={<MyMagazine />} />
          <Route index path={getWebPathParam([Endpoint.ARCHIVE])} element={<Archive />} />
          <Route index path={getWebPathParam([Endpoint.PROFILE])} element={<UserProfile />} />
          <Route index path={getWebPathParam([Endpoint.SETTING])} element={<UserSettings />} />
          <Route index path={getWebPathParam([Endpoint.HOW_WORK])} element={<HowWork Storyblok={Storyblok} />} />
          <Route index path={getWebPathParam([Endpoint.TRANSACTIONS])} element={<UserTransactions />} />
          <Route index path={getWebPathParam([Endpoint.PAYMENT, Endpoint.CHECK])} element={<CheckPayment />} />
          <Route path="*" element={<Page404 />} />
      </Route>



      <Route index path={getWebPathParam([Endpoint.GAME, Endpoint.BRIDGE])} element={<GameBridge />} />

      <Route path="/" element={<RequireAuthGame />}>
        <Route index path={getWebPathParam([Endpoint.GAME, Endpoint.PLAY])} element={<StartGame />} />
        <Route path="*" element={<Page404 />} />
      </Route>

    </Routes>
  );
}

export default Main;
