import React, {Fragment} from "react";
import {Dialog, Transition} from "@headlessui/react";
import {XIcon} from "@heroicons/react/outline/esm";

const GameSlideAlert = ({isOpen, setIsOpen, children, overFlow = ''}) => {


    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 " onClose={() => {
                setIsOpen(false)
            }}>
                <div className="absolute inset-0 ">

                    <div className="fixed inset-x-0 bottom-0  max-w-full  flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enterFrom="translate-y-full"
                            enterTo="translate-y-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                            leaveFrom="translate-y-0"
                            leaveTo="translate-y-full"
                        >
                            <div className={"w-screen max-w-full "}>
                                <div className={"h-auto divide-y divide-gray-200 flex flex-col shadow-xl border-t-2 border-red-600 bg-gray-900 divide-gray-200 rounded-t-[30px] " + overFlow}>

                                    <div className={"flex-1 h-0 "}>
                                        <div className="top-5 right-2 relative flex items-center justify-end z-50">
                                            <button
                                                type="button"
                                                className="text-white hover:text-white/[0.6]  absolute mr-2"
                                                onClick={() => { setIsOpen(false); }}
                                            >
                                                <span className="sr-only">Close panel</span>
                                                <XIcon className="h-5 w-5" aria-hidden="true" />
                                            </button>
                                        </div>
                                        <div className=" mx-auto py-8">
                                            {children}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
};

export default GameSlideAlert;
