import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

const HowWork = ({Storyblok}) => {


    const [pageDetail, setPageDetail] = useState(false);
    let navigate = useNavigate();

    useEffect(() => {
        Storyblok.get("cdn/stories/pagine/come-funziona")
            .then(response => {
                setPageDetail(response.data.story);
            }).catch(error => {
            navigate("/")
        });

    }, [Storyblok, navigate]);


    return (
        <div className="mx-auto max-w-7xl px-6 py-10">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{pageDetail?.content?.contenuto[0]?.title ?? ''}</h2>
            <div className="mt-6  richText" dangerouslySetInnerHTML={{__html:  Storyblok.richTextResolver.render(pageDetail?.content?.contenuto[0]?.description ?? [])}} />
        </div>
    );
};

export default HowWork;
