import React, {useState, useEffect}  from "react";
import { useTranslation } from "react-i18next";
import {useSelector} from "react-redux";
import {getElement} from "../../api/base-api";
import {getWebPathParam} from "../../utils/converter";
import {PAYMENT_TRANSACTIONS, USER} from "../endpoints";
import LoaderWrapper from "../../components/loader/loader-wrapper";
import NumberFormat from "react-number-format";
import {StatusBar} from "../../components/alert/status-bar";
import {getFormattedDate} from "../../utils/timeUtils";
import noTransactions from "../../resources/images/no-transactions.png";
import EmptyState from "../../components/layout/empty-state";

const UserTransactions = () => {

    const { t } = useTranslation();

    const { detail } = useSelector((state) => state.user );
    const [isLoading, setLoading] = useState(false);
    const [transactions, setTransactions] = useState([]);


    useEffect(() => {

        const controller = new AbortController();
        const signal = controller.signal;

        function fetchTransactions() {
            setLoading(true);
            getElement(getWebPathParam([USER, detail.id, PAYMENT_TRANSACTIONS]), signal)
                .then(async (response) => {
                    setTransactions(response.transactions);
                    setLoading(false);

                })
                .catch((e) => {
                    setLoading(false);
                });
        }

        fetchTransactions();

        return () => {
            controller.abort();
        };

    }, [detail]);


    return (
        <div className="py-4">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">


                <div className="divide-y divide-white/5">
                    <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
                        <div>
                            <h2 className="text-base font-semibold leading-7 text-gray-900">
                                {t("app.settings.transactions_title")}
                            </h2>
                            <p className="mt-1 text-sm leading-6 text-gray-700">
                                {t("app.settings.transactions_desc")}
                            </p>
                        </div>

                        <div className="md:col-span-2 bg-white  rounded-md shadow-md" >

                            <LoaderWrapper isLoading={isLoading}>

                                {
                                    transactions.length > 0 ? (
                                        <div className="overflow-x-auto">
                                            <div className="inline-block min-w-full align-middle">
                                                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                                                    <table className="min-w-full divide-y divide-gray-300">
                                                        <thead className="bg-solitude-400">
                                                        <tr>
                                                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                                {t("app.transactions.payment_type")}
                                                            </th>
                                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                                {t("app.transactions.date")}
                                                            </th>
                                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                                {t("app.transactions.price")}
                                                            </th>
                                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                                {t("app.transactions.status")}
                                                            </th>
                                                        </tr>
                                                        </thead>
                                                        <tbody className="divide-y divide-gray-200 bg-white">
                                                        {transactions.map((transaction) => (
                                                            <tr key={transaction.id}>
                                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                                    {transaction.payment_method.title}
                                                                </td>
                                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                    {getFormattedDate(transaction.transaction_date)}
                                                                </td>
                                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                    <NumberFormat
                                                                        value={transaction.price}
                                                                        displayType={"text"}
                                                                        thousandSeparator={"."}
                                                                        decimalScale={2}
                                                                        decimalSeparator={","}
                                                                        prefix={"€ "}
                                                                    />
                                                                </td>
                                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">

                                                                    <StatusBar
                                                                        status={transaction.status}
                                                                        title={t("app.transactions."+transaction.status)}
                                                                    />

                                                                </td>

                                                            </tr>
                                                        ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className={"py-4"}>
                                            <EmptyState
                                                img={noTransactions}
                                                title={t("app.empty_state.transaction_title")}
                                                description={t("app.empty_state.transaction_description")}
                                            />
                                        </div>
                                    )
                                }

                            </LoaderWrapper>






                        </div>
                    </div>
                </div>


            </div>
        </div>
    );
};

export default UserTransactions;
